RBEcommonDef = {};
RBEcommonDef["nullUndef"] = function (X) {
    "use strict";
    if (X === null) return (true);
    if (typeof X === 'undefined') return (true);
    return (false);
};

RBEcommonDef["getPaddedNumberToDigit"] = function(number, digits) {
    "use strict";
    var numLength = (number + "").length;
    var returnVal = "";
    var zeroPadding = "";
    var zeroPaddingCount = digits - numLength;
    if(zeroPaddingCount > 0) {
        while(zeroPaddingCount > 0) {
            zeroPaddingCount--;
            zeroPadding += "0";
        }
    }
    returnVal = zeroPadding + number;
    return returnVal;
};

RBEcommonDef["initArray"] = function(arrLength, val) {
    "use strict";
    var arr = new Array(arrLength);
    if(RBEcommonDef["nullUndef"](val)) return arr;
    for(var index = 0; index < arrLength; index++) {
        arr[index] = val;
    }
    return arr;
};

RBEcommonDef["setCharAt"] = function(str, index, chr) {
    if(index > str.length-1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
};

RBEcommonDef["copyObjectDeep"] = function (fr) {
	if (RBEcommonDef["nullUndef"](fr)) {
		return fr;
	}
	
	var to = {};
    for (var propName in fr) {
        to[propName] = RBEcommonDef["deepCopyObjOrArr"](fr[propName]);
    };
    return (to);
};

RBEcommonDef["copyArrayDeep"] = function (fr) {
	"use strict";
	if (RBEcommonDef["nullUndef"](fr)) {
		return fr;
	}

    var to = [];
    for (var i = 0; i < fr.length; i++) {
		to[i] = RBEcommonDef["deepCopyObjOrArr"](fr[i]);
	};
    //var to = fr.map(function (x) { return (x); });  <<<<<<<This shd replace the above for performance
    return (to);
};

RBEcommonDef["deepCopyObjOrArr"] = function (fr) {
	if (RBEcommonDef["nullUndef"](fr)) {
		return fr;
	}
	
    if (Array["isArray"](fr)) {
		var to = RBEcommonDef["copyArrayDeep"](fr);
	} else if (typeof fr == "object") {
		var to = RBEcommonDef["copyObjectDeep"](fr);
	} else {
		var to = fr;
	}
    return to;
};

RBEcommonDef["binarySearch"] = function (value, array) { //non-recursive case
    var retVal = null, first = 0, last = array["length"] - 1;
    var arrLastEl = array[last];

    if (value == arrLastEl) retVal = last;
    else if (value > arrLastEl) retVal = -1;
    //the above if-then-else stmt assures that array[last] > value, if it enters the loop
    //this assurance is needed when the value is NOT found
    while (!retVal && retVal != 0) {
        var midPoint = Math["floor"]((last + first) / 2),
            midVal = array[midPoint];
        if (midVal == value) { retVal = midPoint; }
        else if (midPoint == first) { retVal = -1; } //the case where the value was NOT found
        else if (midVal < value) { first = midPoint; }
        else { last = midPoint; }; //array[last] > value, is still assured
        midPoint = midVal = null;
    }
    arrLastEl = first = last = null;
    return (retVal);
};

module.exports = RBEcommonDef;