let data = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#94c95a;}</style>
  </defs>
  <title>addIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29,16.88H22.18V10.09a.67.67,0,0,0-.65-.67H19a.67.67,0,0,0-.68.66h0v6.8H11.54a.67.67,0,0,0-.68.64v2.53a.65.65,0,0,0,.63.67h6.83v6.79a.67.67,0,0,0,.67.67H21.5a.67.67,0,0,0,.68-.66h0V20.73H29a.67.67,0,0,0,.68-.66h0V17.55a.67.67,0,0,0-.67-.67Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#94c95a;}</style>
  </defs>
  <title>addIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29,16.88H22.18V10.09a.67.67,0,0,0-.65-.67H19a.67.67,0,0,0-.68.66h0v6.8H11.54a.67.67,0,0,0-.68.64v2.53a.65.65,0,0,0,.63.67h6.83v6.79a.67.67,0,0,0,.67.67H21.5a.67.67,0,0,0,.68-.66h0V20.73H29a.67.67,0,0,0,.68-.66h0V17.55a.67.67,0,0,0-.67-.67Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#94c95a;}</style>
  </defs>
  <title>addIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29,16.88H22.18V10.09a.67.67,0,0,0-.65-.67H19a.67.67,0,0,0-.68.66h0v6.8H11.54a.67.67,0,0,0-.68.64v2.53a.65.65,0,0,0,.63.67h6.83v6.79a.67.67,0,0,0,.67.67H21.5a.67.67,0,0,0,.68-.66h0V20.73H29a.67.67,0,0,0,.68-.66h0V17.55a.67.67,0,0,0-.67-.67Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>addStoryIconOffG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M25.68,12.71H22.42V9.46H11.57V26.29H25.68Zm-3.8,6.06H19.53V21.1H17.69V18.77H15.38V16.92h2.31V14.6h1.84v2.32h2.35Z" transform="translate(0 0.07)"/>
  <polygon class="cls-2" points="27.85 24.18 28.93 24.18 28.93 23.64 28.93 6.28 15.36 6.28 14.82 6.28 14.82 7.36 27.85 7.36 27.85 24.18"/>
  <polygon class="cls-2" points="27.31 24.73 27.31 24.18 27.31 7.89 13.74 7.89 13.19 7.89 13.19 8.98 23.51 8.98 26.22 11.71 26.22 24.73 27.31 24.73"/>
  <polygon class="cls-2" points="22.96 9.54 22.96 12.24 25.68 12.24 22.96 9.54"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>addStoryIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M25.68,12.71H22.42V9.46H11.57V26.29H25.68Zm-3.8,6.06H19.53V21.1H17.69V18.77H15.38V16.92h2.31V14.6h1.84v2.32h2.35Z" transform="translate(0 0.07)"/>
  <polygon class="cls-1" points="27.85 24.18 28.93 24.18 28.93 23.64 28.93 6.28 15.36 6.28 14.82 6.28 14.82 7.36 27.85 7.36 27.85 24.18"/>
  <polygon class="cls-1" points="27.31 24.73 27.31 24.18 27.31 7.89 13.74 7.89 13.19 7.89 13.19 8.98 23.51 8.98 26.22 11.71 26.22 24.73 27.31 24.73"/>
  <polygon class="cls-1" points="22.96 9.54 22.96 12.24 25.68 12.24 22.96 9.54"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>addStoryIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M25.68,12.71H22.42V9.46H11.57V26.29H25.68Zm-3.8,6.06H19.53V21.1H17.69V18.77H15.38V16.92h2.31V14.6h1.84v2.32h2.35Z" transform="translate(0 0.07)"/>
  <polygon class="cls-1" points="27.85 24.18 28.93 24.18 28.93 23.64 28.93 6.28 15.36 6.28 14.82 6.28 14.82 7.36 27.85 7.36 27.85 24.18"/>
  <polygon class="cls-1" points="27.31 24.73 27.31 24.18 27.31 7.89 13.74 7.89 13.19 7.89 13.19 8.98 23.51 8.98 26.22 11.71 26.22 24.73 27.31 24.73"/>
  <polygon class="cls-1" points="22.96 9.54 22.96 12.24 25.68 12.24 22.96 9.54"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.55 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>annotationIconOffG</title>
  <rect class="cls-2" x="0.03" width="40.5" height="46.5"/>
  <path class="cls-1" d="M14.77,22.06h4.4a8.72,8.72,0,0,1-.41,3.86c2.37-.73,3.14-.87,5.69-3.86h1.28a2.39,2.39,0,0,0,2.4-2.4v-5.2a2.42,2.42,0,0,0-2.4-2.4h-11a2.42,2.42,0,0,0-2.4,2.4v5.2a2.39,2.39,0,0,0,2.4,2.4Zm-.72-7.6a.72.72,0,0,1,.72-.72h11a.72.72,0,0,1,.72.72v5.2a.72.72,0,0,1-.72.72H23.68l-.51.59A13.14,13.14,0,0,1,21,23.21a5.69,5.69,0,0,0-.16-1.59l-.34-1.24H14.77a.72.72,0,0,1-.72-.72Z" transform="translate(0 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.55 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>annotationIconGreyG</title>
  <rect class="cls-2" x="0.03" width="40.5" height="46.5"/>
  <path class="cls-1" d="M14.77,22.06h4.4a8.72,8.72,0,0,1-.41,3.86c2.37-.73,3.14-.87,5.69-3.86h1.28a2.39,2.39,0,0,0,2.4-2.4v-5.2a2.42,2.42,0,0,0-2.4-2.4h-11a2.42,2.42,0,0,0-2.4,2.4v5.2a2.39,2.39,0,0,0,2.4,2.4Zm-.72-7.6a.72.72,0,0,1,.72-.72h11a.72.72,0,0,1,.72.72v5.2a.72.72,0,0,1-.72.72H23.68l-.51.59A13.14,13.14,0,0,1,21,23.21a5.69,5.69,0,0,0-.16-1.59l-.34-1.24H14.77a.72.72,0,0,1-.72-.72Z" transform="translate(0 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.55 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>annotationIconOnG</title>
  <rect class="cls-2" x="0.03" width="40.5" height="46.5"/>
  <path class="cls-1" d="M14.77,22.06h4.4a8.72,8.72,0,0,1-.41,3.86c2.37-.73,3.14-.87,5.69-3.86h1.28a2.39,2.39,0,0,0,2.4-2.4v-5.2a2.42,2.42,0,0,0-2.4-2.4h-11a2.42,2.42,0,0,0-2.4,2.4v5.2a2.39,2.39,0,0,0,2.4,2.4Zm-.72-7.6a.72.72,0,0,1,.72-.72h11a.72.72,0,0,1,.72.72v5.2a.72.72,0,0,1-.72.72H23.68l-.51.59A13.14,13.14,0,0,1,21,23.21a5.69,5.69,0,0,0-.16-1.59l-.34-1.24H14.77a.72.72,0,0,1-.72-.72Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <defs>
    <style>.cls-1{fill:#fff;}</style>
  </defs>
  <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" class="cls-1" d="M18.88,16l8.7-8.7A2,2,0,1,0,24.7,4.42h0L16,13.12,7.3,4.42A2,2,0,0,0,4.42,7.3h0l8.7,8.7-8.7,8.7A2,2,0,1,0,7.3,27.58h0l8.7-8.7,8.7,8.7a2,2,0,1,0,2.88-2.88Z"/>
  <title>testTitle</title>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <defs>
    <style>.cls-1{fill:#000;}</style>
  </defs>
  <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" class="cls-1" d="M18.88,16l8.7-8.7A2,2,0,1,0,24.7,4.42h0L16,13.12,7.3,4.42A2,2,0,0,0,4.42,7.3h0l8.7,8.7-8.7,8.7A2,2,0,1,0,7.3,27.58h0l8.7-8.7,8.7,8.7a2,2,0,1,0,2.88-2.88Z"/>
  <title>testTitle</title>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 38 42">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>changeDataIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29.39,18.23l-4.7-4.05v4.08H18.26v3H29.37Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M12,7.69V6.28H24V8.07H16.49a1.84,1.84,0,0,1,.19.79v5.43a1.84,1.84,0,0,1-.07.47,3.29,3.29,0,0,1,.78,1.32h.17a4.17,4.17,0,0,1,.86-.14v-7h2.37v6.93h.86V8.93H24v2.94a2.53,2.53,0,0,1,.67-.1h.19V5.42H11.11V8.19a1.86,1.86,0,0,1,.47-.32Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M11.78,12.14h0a1.8,1.8,0,0,1-.65-.54v2.9a5.51,5.51,0,0,1,.67-.38Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M15.84,20h-2l.5-.42c.13-.11.25-.23.36-.34a4.91,4.91,0,0,0,.72-.86,2.11,2.11,0,0,0,.36-1.14,1.64,1.64,0,0,0-.42-1.14L15.23,16a1.73,1.73,0,0,0-.86-.39,2,2,0,0,0-.49,0,3,3,0,0,0-1.29.29,2.34,2.34,0,0,0-.49.27l.36.91a2,2,0,0,1,1.16-.45.82.82,0,0,1,.75.34.74.74,0,0,1,.15.45,1.2,1.2,0,0,1-.15.52,2.83,2.83,0,0,1-.34.51,9.73,9.73,0,0,1-.84.86l-.45.42-.7.63V21h3.84Z" transform="translate(-0.03 0.07)"/>
  <polygon class="cls-1" points="14.81 14.36 14.81 8.93 13.76 8.93 11.75 10.54 12.53 10.55 13.57 10.54 13.59 10.54 13.59 14.36 14.81 14.36"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 38 42">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>changeDataIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29.39,18.23l-4.7-4.05v4.08H18.26v3H29.37Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M12,7.69V6.28H24V8.07H16.49a1.84,1.84,0,0,1,.19.79v5.43a1.84,1.84,0,0,1-.07.47,3.29,3.29,0,0,1,.78,1.32h.17a4.17,4.17,0,0,1,.86-.14v-7h2.37v6.93h.86V8.93H24v2.94a2.53,2.53,0,0,1,.67-.1h.19V5.42H11.11V8.19a1.86,1.86,0,0,1,.47-.32Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M11.78,12.14h0a1.8,1.8,0,0,1-.65-.54v2.9a5.51,5.51,0,0,1,.67-.38Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M15.84,20h-2l.5-.42c.13-.11.25-.23.36-.34a4.91,4.91,0,0,0,.72-.86,2.11,2.11,0,0,0,.36-1.14,1.64,1.64,0,0,0-.42-1.14L15.23,16a1.73,1.73,0,0,0-.86-.39,2,2,0,0,0-.49,0,3,3,0,0,0-1.29.29,2.34,2.34,0,0,0-.49.27l.36.91a2,2,0,0,1,1.16-.45.82.82,0,0,1,.75.34.74.74,0,0,1,.15.45,1.2,1.2,0,0,1-.15.52,2.83,2.83,0,0,1-.34.51,9.73,9.73,0,0,1-.84.86l-.45.42-.7.63V21h3.84Z" transform="translate(-0.03 0.07)"/>
  <polygon class="cls-1" points="14.81 14.36 14.81 8.93 13.76 8.93 11.75 10.54 12.53 10.55 13.57 10.54 13.59 10.54 13.59 14.36 14.81 14.36"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 38 42">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>changeDataIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M29.39,18.23l-4.7-4.05v4.08H18.26v3H29.37Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M12,7.69V6.28H24V8.07H16.49a1.84,1.84,0,0,1,.19.79v5.43a1.84,1.84,0,0,1-.07.47,3.29,3.29,0,0,1,.78,1.32h.17a4.17,4.17,0,0,1,.86-.14v-7h2.37v6.93h.86V8.93H24v2.94a2.53,2.53,0,0,1,.67-.1h.19V5.42H11.11V8.19a1.86,1.86,0,0,1,.47-.32Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M11.78,12.14h0a1.8,1.8,0,0,1-.65-.54v2.9a5.51,5.51,0,0,1,.67-.38Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M15.84,20h-2l.5-.42c.13-.11.25-.23.36-.34a4.91,4.91,0,0,0,.72-.86,2.11,2.11,0,0,0,.36-1.14,1.64,1.64,0,0,0-.42-1.14L15.23,16a1.73,1.73,0,0,0-.86-.39,2,2,0,0,0-.49,0,3,3,0,0,0-1.29.29,2.34,2.34,0,0,0-.49.27l.36.91a2,2,0,0,1,1.16-.45.82.82,0,0,1,.75.34.74.74,0,0,1,.15.45,1.2,1.2,0,0,1-.15.52,2.83,2.83,0,0,1-.34.51,9.73,9.73,0,0,1-.84.86l-.45.42-.7.63V21h3.84Z" transform="translate(-0.03 0.07)"/>
  <polygon class="cls-1" points="14.81 14.36 14.81 8.93 13.76 8.93 11.75 10.54 12.53 10.55 13.57 10.54 13.59 10.54 13.59 14.36 14.81 14.36"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>cColIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M8,20.6a2.39,2.39,0,0,0,1.4-.5A5.33,5.33,0,0,0,11,17.8c.4-1.1.8-2.1,1.1-3.2l.8-3.1H14l.2-.5h-1a13.79,13.79,0,0,1,.7-2,3.6,3.6,0,0,1,.8-1.2,1.14,1.14,0,0,1,.8-.3h.2v.1c0,.1,0,.1-.1.2a.37.37,0,0,0-.1.3.76.76,0,0,0,.2.4c.1.1.3.2.4.1a.69.69,0,0,0,.7-.6.75.75,0,0,0-.4-.7,1.61,1.61,0,0,0-.9-.3,2,2,0,0,0-1.1.3,5.24,5.24,0,0,0-1.1,1,6.38,6.38,0,0,0-.8,1.4,4.05,4.05,0,0,1-.5.9,1.38,1.38,0,0,1-.4.3,2.2,2.2,0,0,1-.8.1h-.1l-.1.5h1.2l-1.2,4.8a17.18,17.18,0,0,0-.5,2,5.36,5.36,0,0,1-.5,1.1,1,1,0,0,1-.6.5,1.42,1.42,0,0,1-.6.2H8.2l.1-.1a.52.52,0,0,0,.2-.4.76.76,0,0,0-.2-.4c-.1-.1-.2-.1-.4-.1a.76.76,0,0,0-.5.2.51.51,0,0,0-.4.5.76.76,0,0,0,.2.5A1.33,1.33,0,0,0,8,20.6Z"/>
  <path class="cls-1" d="M23.9,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S23.5,17.5,23.9,17.6Z"/>
  <path class="cls-1" d="M20.1,16a1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6a.79.79,0,0,0,.8.8,1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1A.6.6,0,0,0,20.1,16Z"/>
  <path class="cls-1" d="M27.7,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S27.3,17.5,27.7,17.6Z"/>
  <path class="cls-1" d="M16.6,17.6a14.76,14.76,0,0,0,1.2,2.9v.1l.4-.2v-.1a8.36,8.36,0,0,1-.8-3.1,10.72,10.72,0,0,1,.7-3.9,14.88,14.88,0,0,1,1.7-3.6,11,11,0,0,1,2.1-2.2H22l-.3-.4h-.1a15,15,0,0,0-4.3,4.4,8.77,8.77,0,0,0-1.1,4.2A12.86,12.86,0,0,0,16.6,17.6Z"/>
  <path class="cls-1" d="M27.8,20.2l.3.4h.1a16.35,16.35,0,0,0,4.3-4.4A8.77,8.77,0,0,0,33.6,12a6.45,6.45,0,0,0-.3-2,14.76,14.76,0,0,0-1.2-2.9h0l-.4.2v.1a6.81,6.81,0,0,1,.8,3.1,13.36,13.36,0,0,1-.7,3.9,17.64,17.64,0,0,1-1.7,3.7,11.5,11.5,0,0,1-2.3,2.1Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>cColIconGreyG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M8,20.6a2.39,2.39,0,0,0,1.4-.5A5.33,5.33,0,0,0,11,17.8c.4-1.1.8-2.1,1.1-3.2l.8-3.1H14l.2-.5h-1a13.79,13.79,0,0,1,.7-2,3.6,3.6,0,0,1,.8-1.2,1.14,1.14,0,0,1,.8-.3h.2v.1c0,.1,0,.1-.1.2a.37.37,0,0,0-.1.3.76.76,0,0,0,.2.4c.1.1.3.2.4.1a.69.69,0,0,0,.7-.6.75.75,0,0,0-.4-.7,1.61,1.61,0,0,0-.9-.3,2,2,0,0,0-1.1.3,5.24,5.24,0,0,0-1.1,1,6.38,6.38,0,0,0-.8,1.4,4.05,4.05,0,0,1-.5.9,1.38,1.38,0,0,1-.4.3,2.2,2.2,0,0,1-.8.1h-.1l-.1.5h1.2l-1.2,4.8a17.18,17.18,0,0,0-.5,2,5.36,5.36,0,0,1-.5,1.1,1,1,0,0,1-.6.5,1.42,1.42,0,0,1-.6.2H8.2l.1-.1a.52.52,0,0,0,.2-.4.76.76,0,0,0-.2-.4c-.1-.1-.2-.1-.4-.1a.76.76,0,0,0-.5.2.51.51,0,0,0-.4.5.76.76,0,0,0,.2.5A1.33,1.33,0,0,0,8,20.6Z"/>
  <path class="cls-2" d="M23.9,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S23.5,17.5,23.9,17.6Z"/>
  <path class="cls-2" d="M20.1,16a1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6a.79.79,0,0,0,.8.8,1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1A.6.6,0,0,0,20.1,16Z"/>
  <path class="cls-2" d="M27.7,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S27.3,17.5,27.7,17.6Z"/>
  <path class="cls-2" d="M16.6,17.6a14.76,14.76,0,0,0,1.2,2.9v.1l.4-.2v-.1a8.36,8.36,0,0,1-.8-3.1,10.72,10.72,0,0,1,.7-3.9,14.88,14.88,0,0,1,1.7-3.6,11,11,0,0,1,2.1-2.2H22l-.3-.4h-.1a15,15,0,0,0-4.3,4.4,8.77,8.77,0,0,0-1.1,4.2A12.86,12.86,0,0,0,16.6,17.6Z"/>
  <path class="cls-2" d="M27.8,20.2l.3.4h.1a16.35,16.35,0,0,0,4.3-4.4A8.77,8.77,0,0,0,33.6,12a6.45,6.45,0,0,0-.3-2,14.76,14.76,0,0,0-1.2-2.9h0l-.4.2v.1a6.81,6.81,0,0,1,.8,3.1,13.36,13.36,0,0,1-.7,3.9,17.64,17.64,0,0,1-1.7,3.7,11.5,11.5,0,0,1-2.3,2.1Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>cColIconOffG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M8,20.6a2.39,2.39,0,0,0,1.4-.5A5.33,5.33,0,0,0,11,17.8c.4-1.1.8-2.1,1.1-3.2l.8-3.1H14l.2-.5h-1a13.79,13.79,0,0,1,.7-2,3.6,3.6,0,0,1,.8-1.2,1.14,1.14,0,0,1,.8-.3h.2v.1c0,.1,0,.1-.1.2a.37.37,0,0,0-.1.3.76.76,0,0,0,.2.4c.1.1.3.2.4.1a.69.69,0,0,0,.7-.6.75.75,0,0,0-.4-.7,1.61,1.61,0,0,0-.9-.3,2,2,0,0,0-1.1.3,5.24,5.24,0,0,0-1.1,1,6.38,6.38,0,0,0-.8,1.4,4.05,4.05,0,0,1-.5.9,1.38,1.38,0,0,1-.4.3,2.2,2.2,0,0,1-.8.1h-.1l-.1.5h1.2l-1.2,4.8a17.18,17.18,0,0,0-.5,2,5.36,5.36,0,0,1-.5,1.1,1,1,0,0,1-.6.5,1.42,1.42,0,0,1-.6.2H8.2l.1-.1a.52.52,0,0,0,.2-.4.76.76,0,0,0-.2-.4c-.1-.1-.2-.1-.4-.1a.76.76,0,0,0-.5.2.51.51,0,0,0-.4.5.76.76,0,0,0,.2.5A1.33,1.33,0,0,0,8,20.6Z"/>
  <path class="cls-2" d="M23.9,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S23.5,17.5,23.9,17.6Z"/>
  <path class="cls-2" d="M20.1,16a1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6a.79.79,0,0,0,.8.8,1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1A.6.6,0,0,0,20.1,16Z"/>
  <path class="cls-2" d="M27.7,17.6a1.42,1.42,0,0,0,.6-.2.78.78,0,0,0,0-1.1,1.07,1.07,0,0,0-.6-.2,1.42,1.42,0,0,0-.6.2c-.2.1-.2.4-.2.6S27.3,17.5,27.7,17.6Z"/>
  <path class="cls-2" d="M16.6,17.6a14.76,14.76,0,0,0,1.2,2.9v.1l.4-.2v-.1a8.36,8.36,0,0,1-.8-3.1,10.72,10.72,0,0,1,.7-3.9,14.88,14.88,0,0,1,1.7-3.6,11,11,0,0,1,2.1-2.2H22l-.3-.4h-.1a15,15,0,0,0-4.3,4.4,8.77,8.77,0,0,0-1.1,4.2A12.86,12.86,0,0,0,16.6,17.6Z"/>
  <path class="cls-2" d="M27.8,20.2l.3.4h.1a16.35,16.35,0,0,0,4.3-4.4A8.77,8.77,0,0,0,33.6,12a6.45,6.45,0,0,0-.3-2,14.76,14.76,0,0,0-1.2-2.9h0l-.4.2v.1a6.81,6.81,0,0,1,.8,3.1,13.36,13.36,0,0,1-.7,3.9,17.64,17.64,0,0,1-1.7,3.7,11.5,11.5,0,0,1-2.3,2.1Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <defs>
    <style>.cls-1{fill:#4c4c4c;}</style>
  </defs>
  <title>dataHeaderIconOffG</title>
  <circle id="Ellipse_9" data-name="Ellipse 9" class="cls-1" cx="7.4" cy="8.13" r="2.57"/>
  <circle id="Ellipse_10" data-name="Ellipse 10" class="cls-1" cx="16" cy="8.13" r="2.57"/>
  <circle id="Ellipse_11" data-name="Ellipse 11" class="cls-1" cx="24.6" cy="8.13" r="2.57"/>
  <circle id="Ellipse_12" data-name="Ellipse 12" class="cls-1" cx="7.4" cy="16" r="2.57"/>
  <circle id="Ellipse_13" data-name="Ellipse 13" class="cls-1" cx="16" cy="16" r="2.57"/>
  <circle id="Ellipse_14" data-name="Ellipse 14" class="cls-1" cx="24.6" cy="16" r="2.57"/>
  <circle id="Ellipse_15" data-name="Ellipse 15" class="cls-1" cx="7.4" cy="23.87" r="2.57"/>
  <circle id="Ellipse_16" data-name="Ellipse 16" class="cls-1" cx="16" cy="23.87" r="2.57"/>
  <circle id="Ellipse_17" data-name="Ellipse 17" class="cls-1" cx="24.6" cy="23.87" r="2.57"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="-10 -7 56 56">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>ingestionStatusIconOffG</title>
  <path class="cls-1" d="M16.5,26.9A11.54,11.54,0,0,1,5,16.52a11.59,11.59,0,0,1,22.63-4.33c.25.86.57,1.23,1.52,1.17s1.09.43.57,1.09c-.72.9-1.49,1.75-2.22,2.65-.45.55-.85.48-1.26,0-.72-.85-1.47-1.68-2.15-2.56s-.38-1.22.57-1.17c1.23.07,1-.48.72-1.25a9.49,9.49,0,0,0-18.21,2A9.41,9.41,0,0,0,12.65,24a9.55,9.55,0,0,0,11.22-2.69c.3-.37.55-.78.84-1.16a1,1,0,0,1,1.45-.37c.64.39.62,1,.28,1.55a11,11,0,0,1-3.12,3.4A11.7,11.7,0,0,1,16.5,26.9Z"/>
  <path class="cls-1" d="M15.29,19.48l-4-2.45A.73.73,0,0,1,11,16l.32-.43a.88.88,0,0,1,1.17-.21l2.32,1.4A.91.91,0,0,0,16,16.56l4.37-5a.93.93,0,0,1,1.2-.12l.44.33a.73.73,0,0,1,.13,1.08l-5.67,6.45A.91.91,0,0,1,15.29,19.48Z"/>
  <circle class="cls-1" cx="26.31" cy="18.42" r="0.58"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="-10 -7 56 56">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>ingestionStatusIconGreyG</title>
  <path class="cls-1" d="M16.5,26.9A11.54,11.54,0,0,1,5,16.52a11.59,11.59,0,0,1,22.63-4.33c.25.86.57,1.23,1.52,1.17s1.09.43.57,1.09c-.72.9-1.49,1.75-2.22,2.65-.45.55-.85.48-1.26,0-.72-.85-1.47-1.68-2.15-2.56s-.38-1.22.57-1.17c1.23.07,1-.48.72-1.25a9.49,9.49,0,0,0-18.21,2A9.41,9.41,0,0,0,12.65,24a9.55,9.55,0,0,0,11.22-2.69c.3-.37.55-.78.84-1.16a1,1,0,0,1,1.45-.37c.64.39.62,1,.28,1.55a11,11,0,0,1-3.12,3.4A11.7,11.7,0,0,1,16.5,26.9Z"/>
  <path class="cls-1" d="M15.29,19.48l-4-2.45A.73.73,0,0,1,11,16l.32-.43a.88.88,0,0,1,1.17-.21l2.32,1.4A.91.91,0,0,0,16,16.56l4.37-5a.93.93,0,0,1,1.2-.12l.44.33a.73.73,0,0,1,.13,1.08l-5.67,6.45A.91.91,0,0,1,15.29,19.48Z"/>
  <circle class="cls-1" cx="26.31" cy="18.42" r="0.58"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="-10 -7 56 56">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>ingestionStatusIconOnG</title>
  <path class="cls-1" d="M16.5,26.9A11.54,11.54,0,0,1,5,16.52a11.59,11.59,0,0,1,22.63-4.33c.25.86.57,1.23,1.52,1.17s1.09.43.57,1.09c-.72.9-1.49,1.75-2.22,2.65-.45.55-.85.48-1.26,0-.72-.85-1.47-1.68-2.15-2.56s-.38-1.22.57-1.17c1.23.07,1-.48.72-1.25a9.49,9.49,0,0,0-18.21,2A9.41,9.41,0,0,0,12.65,24a9.55,9.55,0,0,0,11.22-2.69c.3-.37.55-.78.84-1.16a1,1,0,0,1,1.45-.37c.64.39.62,1,.28,1.55a11,11,0,0,1-3.12,3.4A11.7,11.7,0,0,1,16.5,26.9Z"/>
  <path class="cls-1" d="M15.29,19.48l-4-2.45A.73.73,0,0,1,11,16l.32-.43a.88.88,0,0,1,1.17-.21l2.32,1.4A.91.91,0,0,0,16,16.56l4.37-5a.93.93,0,0,1,1.2-.12l.44.33a.73.73,0,0,1,.13,1.08l-5.67,6.45A.91.91,0,0,1,15.29,19.48Z"/>
  <circle class="cls-1" cx="26.31" cy="18.42" r="0.58"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>deleteIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M28.17,12.76l-1.86-1.87a.7.7,0,0,0-1,0h0L20.25,16,15.2,10.89a.72.72,0,0,0-1,0l-1.87,1.87a.69.69,0,0,0,0,1l0,0,5.06,5.06-5.06,5.06a.68.68,0,0,0,0,1l0,0,1.86,1.86a.7.7,0,0,0,1,0h0l5-5.06,5.06,5.06a.69.69,0,0,0,1,0l0,0,1.87-1.86a.72.72,0,0,0,0-1l-5.06-5.05,5.06-5.06a.69.69,0,0,0,0-1Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>deleteIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M28.17,12.76l-1.86-1.87a.7.7,0,0,0-1,0h0L20.25,16,15.2,10.89a.72.72,0,0,0-1,0l-1.87,1.87a.69.69,0,0,0,0,1l0,0,5.06,5.06-5.06,5.06a.68.68,0,0,0,0,1l0,0,1.86,1.86a.7.7,0,0,0,1,0h0l5-5.06,5.06,5.06a.69.69,0,0,0,1,0l0,0,1.87-1.86a.72.72,0,0,0,0-1l-5.06-5.05,5.06-5.06a.69.69,0,0,0,0-1Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>deleteIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M28.17,12.76l-1.86-1.87a.7.7,0,0,0-1,0h0L20.25,16,15.2,10.89a.72.72,0,0,0-1,0l-1.87,1.87a.69.69,0,0,0,0,1l0,0,5.06,5.06-5.06,5.06a.68.68,0,0,0,0,1l0,0,1.86,1.86a.7.7,0,0,0,1,0h0l5-5.06,5.06,5.06a.69.69,0,0,0,1,0l0,0,1.87-1.86a.72.72,0,0,0,0-1l-5.06-5.05,5.06-5.06a.69.69,0,0,0,0-1Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.49">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>disableUserOffG</title>
  <rect class="cls-2" width="40.5" height="46.49"/>
  <path class="cls-1" d="M19.71,23.81a9,9,0,0,0,9-9,9,9,0,0,0-.14-1.36,3.85,3.85,0,0,1-1.22.75v.61A7.53,7.53,0,0,1,24.13,21a4.91,4.91,0,0,0-3.06-3.2,3.28,3.28,0,0,0,1.63-3.54A5.73,5.73,0,0,1,20,11.76a3.17,3.17,0,0,0-1.91.34,3.24,3.24,0,0,0-1.36,4.36,2.94,2.94,0,0,0,1.36,1.36A4.82,4.82,0,0,0,15,21a7.63,7.63,0,0,1-1.63-10.69,7.73,7.73,0,0,1,6.27-3.21h.13a5.63,5.63,0,0,1,.68-1.22c-.27,0-.54-.07-.81-.07a9,9,0,0,0-9,9A9.08,9.08,0,0,0,19.71,23.81Z" transform="translate(0 0)"/>
  <path class="cls-1" d="M20.11,9.17a4.83,4.83,0,0,0,1,2.93,5.75,5.75,0,0,0,1.29,1.22,4.79,4.79,0,0,0,2.59.75,5,5,0,0,0,2.25-.54,4.62,4.62,0,0,0,1.16-.82,5,5,0,0,0,1.5-3.54A4.89,4.89,0,0,0,25,4.26,5.12,5.12,0,0,0,21.2,6a5.83,5.83,0,0,0-.75,1.23A6.48,6.48,0,0,0,20.11,9.17Zm8.59,0A4,4,0,0,1,28,11.35a3.46,3.46,0,0,1-1,1,3.64,3.64,0,0,1-1.9.55A3.1,3.1,0,0,1,23,12.16l5-5A3.33,3.33,0,0,1,28.7,9.17ZM21.82,7.4a4.31,4.31,0,0,1,.88-1.09A3.82,3.82,0,0,1,25,5.49a4,4,0,0,1,2,.61l-5,5a3.27,3.27,0,0,1-.61-2A3,3,0,0,1,21.82,7.4Z" transform="translate(0 0)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.49">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>disableUserGreyG</title>
  <rect class="cls-2" width="40.5" height="46.49"/>
  <path class="cls-1" d="M19.71,23.81a9,9,0,0,0,9-9,9,9,0,0,0-.14-1.36,3.85,3.85,0,0,1-1.22.75v.61A7.53,7.53,0,0,1,24.13,21a4.91,4.91,0,0,0-3.06-3.2,3.28,3.28,0,0,0,1.63-3.54A5.73,5.73,0,0,1,20,11.76a3.17,3.17,0,0,0-1.91.34,3.24,3.24,0,0,0-1.36,4.36,2.94,2.94,0,0,0,1.36,1.36A4.82,4.82,0,0,0,15,21a7.63,7.63,0,0,1-1.63-10.69,7.73,7.73,0,0,1,6.27-3.21h.13a5.63,5.63,0,0,1,.68-1.22c-.27,0-.54-.07-.81-.07a9,9,0,0,0-9,9A9.08,9.08,0,0,0,19.71,23.81Z" transform="translate(0 0)"/>
  <path class="cls-1" d="M20.11,9.17a4.83,4.83,0,0,0,1,2.93,5.75,5.75,0,0,0,1.29,1.22,4.79,4.79,0,0,0,2.59.75,5,5,0,0,0,2.25-.54,4.62,4.62,0,0,0,1.16-.82,5,5,0,0,0,1.5-3.54A4.89,4.89,0,0,0,25,4.26,5.12,5.12,0,0,0,21.2,6a5.83,5.83,0,0,0-.75,1.23A6.48,6.48,0,0,0,20.11,9.17Zm8.59,0A4,4,0,0,1,28,11.35a3.46,3.46,0,0,1-1,1,3.64,3.64,0,0,1-1.9.55A3.1,3.1,0,0,1,23,12.16l5-5A3.33,3.33,0,0,1,28.7,9.17ZM21.82,7.4a4.31,4.31,0,0,1,.88-1.09A3.82,3.82,0,0,1,25,5.49a4,4,0,0,1,2,.61l-5,5a3.27,3.27,0,0,1-.61-2A3,3,0,0,1,21.82,7.4Z" transform="translate(0 0)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.49">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>disableUserOnG</title>
  <rect class="cls-2" width="40.5" height="46.49"/>
  <path class="cls-1" d="M19.71,23.81a9,9,0,0,0,9-9,9,9,0,0,0-.14-1.36,3.85,3.85,0,0,1-1.22.75v.61A7.53,7.53,0,0,1,24.13,21a4.91,4.91,0,0,0-3.06-3.2,3.28,3.28,0,0,0,1.63-3.54A5.73,5.73,0,0,1,20,11.76a3.17,3.17,0,0,0-1.91.34,3.24,3.24,0,0,0-1.36,4.36,2.94,2.94,0,0,0,1.36,1.36A4.82,4.82,0,0,0,15,21a7.63,7.63,0,0,1-1.63-10.69,7.73,7.73,0,0,1,6.27-3.21h.13a5.63,5.63,0,0,1,.68-1.22c-.27,0-.54-.07-.81-.07a9,9,0,0,0-9,9A9.08,9.08,0,0,0,19.71,23.81Z" transform="translate(0 0)"/>
  <path class="cls-1" d="M20.11,9.17a4.83,4.83,0,0,0,1,2.93,5.75,5.75,0,0,0,1.29,1.22,4.79,4.79,0,0,0,2.59.75,5,5,0,0,0,2.25-.54,4.62,4.62,0,0,0,1.16-.82,5,5,0,0,0,1.5-3.54A4.89,4.89,0,0,0,25,4.26,5.12,5.12,0,0,0,21.2,6a5.83,5.83,0,0,0-.75,1.23A6.48,6.48,0,0,0,20.11,9.17Zm8.59,0A4,4,0,0,1,28,11.35a3.46,3.46,0,0,1-1,1,3.64,3.64,0,0,1-1.9.55A3.1,3.1,0,0,1,23,12.16l5-5A3.33,3.33,0,0,1,28.7,9.17ZM21.82,7.4a4.31,4.31,0,0,1,.88-1.09A3.82,3.82,0,0,1,25,5.49a4,4,0,0,1,2,.61l-5,5a3.27,3.27,0,0,1-.61-2A3,3,0,0,1,21.82,7.4Z" transform="translate(0 0)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>downloadIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>downloadIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>downloadIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>editIconOffG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M26.86,23.36v-8L25.1,17.14v6.22a.08.08,0,0,1-.07.07H12.56a.08.08,0,0,1-.07-.07V10.58a.07.07,0,0,1,.07-.07h7.08L21.4,8.75H12.56a1.83,1.83,0,0,0-1.83,1.83V23.36a1.83,1.83,0,0,0,1.83,1.83H25A1.83,1.83,0,0,0,26.86,23.36Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,12.27a.88.88,0,0,0,0,1.76h2.54l0-.17a1.67,1.67,0,0,1,.47-.85l.74-.74Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,17.85h1.64l.41-1.76H14.09a.88.88,0,1,0,0,1.76Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,21.67h9.4a.89.89,0,0,0,0-1.77H20.73l-3,.71a1.83,1.83,0,0,1-.4.05,1.76,1.76,0,0,1-1.25-.52,1.5,1.5,0,0,1-.18-.24H14.09a.89.89,0,0,0,0,1.77Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M29,7.24a2.82,2.82,0,0,0-4,0l-6.69,6.69a.87.87,0,0,0-.23.42l-.83,3.56a.87.87,0,0,0,.13.7l-.06.29.28-.07a.9.9,0,0,0,.5.16l.21,0,3.55-.83a.85.85,0,0,0,.42-.24L29,11.21a2.81,2.81,0,0,0,0-4Zm-7.76,9.24-1.93.45L19.72,15l4.8-4.8L26,11.68ZM27.71,10l-.47.47L25.76,9l.47-.46a1.06,1.06,0,0,1,1.48,0,1.05,1.05,0,0,1,0,1.47Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>editIconGreyG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M26.86,23.36v-8L25.1,17.14v6.22a.08.08,0,0,1-.07.07H12.56a.08.08,0,0,1-.07-.07V10.58a.07.07,0,0,1,.07-.07h7.08L21.4,8.75H12.56a1.83,1.83,0,0,0-1.83,1.83V23.36a1.83,1.83,0,0,0,1.83,1.83H25A1.83,1.83,0,0,0,26.86,23.36Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,12.27a.88.88,0,0,0,0,1.76h2.54l0-.17a1.67,1.67,0,0,1,.47-.85l.74-.74Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,17.85h1.64l.41-1.76H14.09a.88.88,0,1,0,0,1.76Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M14.09,21.67h9.4a.89.89,0,0,0,0-1.77H20.73l-3,.71a1.83,1.83,0,0,1-.4.05,1.76,1.76,0,0,1-1.25-.52,1.5,1.5,0,0,1-.18-.24H14.09a.89.89,0,0,0,0,1.77Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M29,7.24a2.82,2.82,0,0,0-4,0l-6.69,6.69a.87.87,0,0,0-.23.42l-.83,3.56a.87.87,0,0,0,.13.7l-.06.29.28-.07a.9.9,0,0,0,.5.16l.21,0,3.55-.83a.85.85,0,0,0,.42-.24L29,11.21a2.81,2.81,0,0,0,0-4Zm-7.76,9.24-1.93.45L19.72,15l4.8-4.8L26,11.68ZM27.71,10l-.47.47L25.76,9l.47-.46a1.06,1.06,0,0,1,1.48,0,1.05,1.05,0,0,1,0,1.47Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>editIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M26.86,23.36v-8L25.1,17.14v6.22a.08.08,0,0,1-.07.07H12.56a.08.08,0,0,1-.07-.07V10.58a.07.07,0,0,1,.07-.07h7.08L21.4,8.75H12.56a1.83,1.83,0,0,0-1.83,1.83V23.36a1.83,1.83,0,0,0,1.83,1.83H25A1.83,1.83,0,0,0,26.86,23.36Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M14.09,12.27a.88.88,0,0,0,0,1.76h2.54l0-.17a1.67,1.67,0,0,1,.47-.85l.74-.74Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M14.09,17.85h1.64l.41-1.76H14.09a.88.88,0,1,0,0,1.76Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M14.09,21.67h9.4a.89.89,0,0,0,0-1.77H20.73l-3,.71a1.83,1.83,0,0,1-.4.05,1.76,1.76,0,0,1-1.25-.52,1.5,1.5,0,0,1-.18-.24H14.09a.89.89,0,0,0,0,1.77Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M29,7.24a2.82,2.82,0,0,0-4,0l-6.69,6.69a.87.87,0,0,0-.23.42l-.83,3.56a.87.87,0,0,0,.13.7l-.06.29.28-.07a.9.9,0,0,0,.5.16l.21,0,3.55-.83a.85.85,0,0,0,.42-.24L29,11.21a2.81,2.81,0,0,0,0-4Zm-7.76,9.24-1.93.45L19.72,15l4.8-4.8L26,11.68ZM27.71,10l-.47.47L25.76,9l.47-.46a1.06,1.06,0,0,1,1.48,0,1.05,1.05,0,0,1,0,1.47Z" transform="translate(0 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>generateCSVIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>generateCSVIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>generateCSVIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="24.02" y="21.73" width="7.98" height="1.44"/>
  <path class="cls-1" d="M9.16,19.66h5.51V22a1.06,1.06,0,0,0,1.05,1.06H22.2V21.67H16.08v-2h5.39a.69.69,0,0,0,.69-.69h0v-4.9a.69.69,0,0,0-.69-.69H16.08V5.69h8.47v4.59a1.08,1.08,0,0,0,1.06,1.06H30.2v1.07h1.41V10.28a3,3,0,0,0-.75-1.81L27.42,5a3,3,0,0,0-1.81-.75H15.72a1,1,0,0,0-1.05,1h0v8H9.16a.69.69,0,0,0-.69.69v5A.69.69,0,0,0,9.16,19.66ZM26,5.78a1.35,1.35,0,0,1,.46.24l3.45,3.45a1.35,1.35,0,0,1,.24.46H26Zm-7.91,8.87.46,1.58c.12.45.24.88.33,1.34h0c.09-.45.21-.89.34-1.32l.47-1.6h.91L19.31,18.4h-1l-1.2-3.75ZM15,16.82c-.62-.21-1-.56-1-1.1a1.3,1.3,0,0,1,1.42-1.13,2.15,2.15,0,0,1,1,.19l-.19.68a1.82,1.82,0,0,0-.79-.18c-.36,0-.54.17-.54.36s.21.35.69.53c.67.25,1,.59,1,1.12s-.49,1.16-1.52,1.16A2.41,2.41,0,0,1,14,18.22l.17-.7a2.18,2.18,0,0,0,.94.24c.39,0,.6-.16.6-.41s-.31-.35-.76-.53Zm-3-2.23a2.28,2.28,0,0,1,.93.16l-.17.68a1.78,1.78,0,0,0-.72-.14,1.11,1.11,0,0,0-1.19,1,.67.67,0,0,0,0,.2,1.11,1.11,0,0,0,1,1.22.74.74,0,0,0,.21,0,2.16,2.16,0,0,0,.71-.12l.12.66a2.29,2.29,0,0,1-1,.16,1.77,1.77,0,0,1-2-1.52,2,2,0,0,1,0-.35,1.91,1.91,0,0,1,1.82-2Z" transform="translate(-0.03 0.07)"/>
  <path class="cls-1" d="M26.94,13.69v2.84H24.49l3.55,4,3.55-4H29.14V13.69Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.44 46.43">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>groupsOffG</title>
  <rect class="cls-2" width="40.44" height="46.43"/>
  <path class="cls-1" d="M11.22,11.9c0-1.35.52-2.56,2.3-2.56h1.17v1.6H14c-.52,0-.84.37-.84,1.19v8.74c0,.81.32,1.18.84,1.18h.72v1.61H13.52c-2,0-2.3-1.62-2.3-2.72Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M15.75,19.26a.31.31,0,0,1,.33-.34h1.17c.25,0,.34.12.34.34v1.23c0,.24-.15.31-.34.31H16.08a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M19.33,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H19.66a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M22.91,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H23.24a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M26.53,22.05c.52,0,.84-.37.84-1.18V12.13c0-.82-.32-1.19-.84-1.19h-.72V9.34H27c1.78,0,2.3,1.21,2.3,2.56v9c0,1.1-.3,2.72-2.3,2.72H25.81V22.05Z" transform="translate(-0.03 -0.04)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.44 46.43">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>groupsGreyG</title>
  <rect class="cls-2" width="40.44" height="46.43"/>
  <path class="cls-1" d="M11.22,11.9c0-1.35.52-2.56,2.3-2.56h1.17v1.6H14c-.52,0-.84.37-.84,1.19v8.74c0,.81.32,1.18.84,1.18h.72v1.61H13.52c-2,0-2.3-1.62-2.3-2.72Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M15.75,19.26a.31.31,0,0,1,.33-.34h1.17c.25,0,.34.12.34.34v1.23c0,.24-.15.31-.34.31H16.08a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M19.33,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H19.66a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M22.91,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H23.24a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M26.53,22.05c.52,0,.84-.37.84-1.18V12.13c0-.82-.32-1.19-.84-1.19h-.72V9.34H27c1.78,0,2.3,1.21,2.3,2.56v9c0,1.1-.3,2.72-2.3,2.72H25.81V22.05Z" transform="translate(-0.03 -0.04)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.44 46.43">
  <defs>
    <style>.cls-2{fill:none;} .cls-1{fill:#424B53;}</style>
  </defs>
  <title>groupsOnG</title>
  <rect class="cls-2" width="40.44" height="46.43"/>
  <path class="cls-1" d="M11.22,11.9c0-1.35.52-2.56,2.3-2.56h1.17v1.6H14c-.52,0-.84.37-.84,1.19v8.74c0,.81.32,1.18.84,1.18h.72v1.61H13.52c-2,0-2.3-1.62-2.3-2.72Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M15.75,19.26a.31.31,0,0,1,.33-.34h1.17c.25,0,.34.12.34.34v1.23c0,.24-.15.31-.34.31H16.08a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M19.33,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H19.66a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M22.91,19.26a.31.31,0,0,1,.33-.34h1.17a.3.3,0,0,1,.34.34v1.23c0,.24-.15.31-.34.31H23.24a.3.3,0,0,1-.33-.31Z" transform="translate(-0.03 -0.04)"/>
  <path class="cls-1" d="M26.53,22.05c.52,0,.84-.37.84-1.18V12.13c0-.82-.32-1.19-.84-1.19h-.72V9.34H27c1.78,0,2.3,1.21,2.3,2.56v9c0,1.1-.3,2.72-2.3,2.72H25.81V22.05Z" transform="translate(-0.03 -0.04)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>morphIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M27.78,13.79a.59.59,0,0,0-.29.07,11.14,11.14,0,0,0-.37-2.37c-.88-3.33-3.07-5.66-4.95-5.31h-.08a1,1,0,0,0-.76,1,1.05,1.05,0,0,0,2.1,0h0c1.5.47,3,2.33,3.64,4.81a10.29,10.29,0,0,1,.31,2,3.51,3.51,0,0,0-.94,1.78,15.89,15.89,0,0,0-3-4.31c-3.87-4-8.93-5.38-11.29-3.09S11,15.73,14.83,19.73a16.33,16.33,0,0,0,3.07,2.49c-1.79,2.66-2.09,5.55-.58,6.85,1.71,1.46,5.11.31,7.59-2.59a11,11,0,0,0,1.76-2.8c.3.72.69,1.15,1.11,1.15,1,0,1.82-2.47,1.82-5.52S28.79,13.79,27.78,13.79ZM25.09,25.45c-2.16,2.52-4.75,3.28-6.24,2-1.22-1-1.12-3.42.15-5.58-.18-.12-9.07-7.77-5.43-12,1.92-2.26,6.64-.87,10.11,2.75a14,14,0,0,1,2.58,3.65l-.06.29c-.36-.45-.77-.92-1.22-1.38-2.23-2.3-4.58-3.66-5.24-3-.41.4-.06,1.5.82,2.84-1.52-.49-2.68-.54-2.95,0s.32,1.35,1.42,2.29c-1.45,0-2.45.28-2.57.81-.15.72,1.35,1.68,3.57,2.38.55.18,1.15.33,1.77.47l.89.17c-2,2.15-3.11,4.29-2.5,4.89s3-.69,5.26-3c.27-.27.53-.55.77-.82a7.8,7.8,0,0,0,.29,1.08A9.38,9.38,0,0,1,25.09,25.45Zm-2.2-4.66c-2.58-.56-4.53-1.66-4.35-2.46.08-.39.68-.64,1.57-.73a2.34,2.34,0,0,1-.24-.31c-.35-.5-.47-.94-.3-1.25s.9-.52,1.81-.37a3.88,3.88,0,0,1-.22-.46c-.27-.66-.31-1.17-.06-1.42.6-.57,2.56.49,4.39,2.38.23.24.45.48.65.72-.05.29-.08.59-.11.9a5.88,5.88,0,0,1-.06.77,3.14,3.14,0,0,0,0,.75A15.18,15.18,0,0,0,26.19,22h0c-1.79,1.82-3.68,2.86-4.23,2.32s.13-1.74,1.29-3.19l.23-.27Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>morphIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M27.78,13.79a.59.59,0,0,0-.29.07,11.14,11.14,0,0,0-.37-2.37c-.88-3.33-3.07-5.66-4.95-5.31h-.08a1,1,0,0,0-.76,1,1.05,1.05,0,0,0,2.1,0h0c1.5.47,3,2.33,3.64,4.81a10.29,10.29,0,0,1,.31,2,3.51,3.51,0,0,0-.94,1.78,15.89,15.89,0,0,0-3-4.31c-3.87-4-8.93-5.38-11.29-3.09S11,15.73,14.83,19.73a16.33,16.33,0,0,0,3.07,2.49c-1.79,2.66-2.09,5.55-.58,6.85,1.71,1.46,5.11.31,7.59-2.59a11,11,0,0,0,1.76-2.8c.3.72.69,1.15,1.11,1.15,1,0,1.82-2.47,1.82-5.52S28.79,13.79,27.78,13.79ZM25.09,25.45c-2.16,2.52-4.75,3.28-6.24,2-1.22-1-1.12-3.42.15-5.58-.18-.12-9.07-7.77-5.43-12,1.92-2.26,6.64-.87,10.11,2.75a14,14,0,0,1,2.58,3.65l-.06.29c-.36-.45-.77-.92-1.22-1.38-2.23-2.3-4.58-3.66-5.24-3-.41.4-.06,1.5.82,2.84-1.52-.49-2.68-.54-2.95,0s.32,1.35,1.42,2.29c-1.45,0-2.45.28-2.57.81-.15.72,1.35,1.68,3.57,2.38.55.18,1.15.33,1.77.47l.89.17c-2,2.15-3.11,4.29-2.5,4.89s3-.69,5.26-3c.27-.27.53-.55.77-.82a7.8,7.8,0,0,0,.29,1.08A9.38,9.38,0,0,1,25.09,25.45Zm-2.2-4.66c-2.58-.56-4.53-1.66-4.35-2.46.08-.39.68-.64,1.57-.73a2.34,2.34,0,0,1-.24-.31c-.35-.5-.47-.94-.3-1.25s.9-.52,1.81-.37a3.88,3.88,0,0,1-.22-.46c-.27-.66-.31-1.17-.06-1.42.6-.57,2.56.49,4.39,2.38.23.24.45.48.65.72-.05.29-.08.59-.11.9a5.88,5.88,0,0,1-.06.77,3.14,3.14,0,0,0,0,.75A15.18,15.18,0,0,0,26.19,22h0c-1.79,1.82-3.68,2.86-4.23,2.32s.13-1.74,1.29-3.19l.23-.27Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>morphIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M27.78,13.79a.59.59,0,0,0-.29.07,11.14,11.14,0,0,0-.37-2.37c-.88-3.33-3.07-5.66-4.95-5.31h-.08a1,1,0,0,0-.76,1,1.05,1.05,0,0,0,2.1,0h0c1.5.47,3,2.33,3.64,4.81a10.29,10.29,0,0,1,.31,2,3.51,3.51,0,0,0-.94,1.78,15.89,15.89,0,0,0-3-4.31c-3.87-4-8.93-5.38-11.29-3.09S11,15.73,14.83,19.73a16.33,16.33,0,0,0,3.07,2.49c-1.79,2.66-2.09,5.55-.58,6.85,1.71,1.46,5.11.31,7.59-2.59a11,11,0,0,0,1.76-2.8c.3.72.69,1.15,1.11,1.15,1,0,1.82-2.47,1.82-5.52S28.79,13.79,27.78,13.79ZM25.09,25.45c-2.16,2.52-4.75,3.28-6.24,2-1.22-1-1.12-3.42.15-5.58-.18-.12-9.07-7.77-5.43-12,1.92-2.26,6.64-.87,10.11,2.75a14,14,0,0,1,2.58,3.65l-.06.29c-.36-.45-.77-.92-1.22-1.38-2.23-2.3-4.58-3.66-5.24-3-.41.4-.06,1.5.82,2.84-1.52-.49-2.68-.54-2.95,0s.32,1.35,1.42,2.29c-1.45,0-2.45.28-2.57.81-.15.72,1.35,1.68,3.57,2.38.55.18,1.15.33,1.77.47l.89.17c-2,2.15-3.11,4.29-2.5,4.89s3-.69,5.26-3c.27-.27.53-.55.77-.82a7.8,7.8,0,0,0,.29,1.08A9.38,9.38,0,0,1,25.09,25.45Zm-2.2-4.66c-2.58-.56-4.53-1.66-4.35-2.46.08-.39.68-.64,1.57-.73a2.34,2.34,0,0,1-.24-.31c-.35-.5-.47-.94-.3-1.25s.9-.52,1.81-.37a3.88,3.88,0,0,1-.22-.46c-.27-.66-.31-1.17-.06-1.42.6-.57,2.56.49,4.39,2.38.23.24.45.48.65.72-.05.29-.08.59-.11.9a5.88,5.88,0,0,1-.06.77,3.14,3.14,0,0,0,0,.75A15.18,15.18,0,0,0,26.19,22h0c-1.79,1.82-3.68,2.86-4.23,2.32s.13-1.74,1.29-3.19l.23-.27Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>moveIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M19.21,26.55,29.48,19,19.21,11.51V16.7H11v4.66h8.17Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>moveIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M19.21,26.55,29.48,19,19.21,11.51V16.7H11v4.66h8.17Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>moveIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M19.21,26.55,29.48,19,19.21,11.51V16.7H11v4.66h8.17Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-3{fill:#fff;}.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>optimizeFilterIconOffG</title>
  <path class="cls-1" d="M1.72,12.63c.46.1.88.23,1.32.29,1.29.17,2.59.35,3.89.46,3,.26,5.92.35,8.89.32,2.7,0,5.4-.12,8.1-.24,1.47-.07,2.94-.25,4.4-.42.64-.08,1.28-.26,1.95-.4v.72c0,.65,0,1.29,0,1.94a.37.37,0,0,1-.33.44c-.73.14-1.46.32-2.21.42a101.5,101.5,0,0,1-14.38.58,77.55,77.55,0,0,1-8.75-.54c-.88-.1-1.74-.32-2.61-.49-.21,0-.29-.15-.28-.38C1.73,14.44,1.72,13.55,1.72,12.63Z"/>
  <path class="cls-1" d="M14,29.38v-.33c0-1.43,0-2.86,0-4.29a2.53,2.53,0,0,0-1.14-2.21C10.63,21,8.42,19.37,6.2,17.77a2.24,2.24,0,0,1-.35-.29,134.94,134.94,0,0,0,19.39,0l-.26.2-6.85,4.93A2.41,2.41,0,0,0,17,24.66c0,.82,0,1.65,0,2.47a.69.69,0,0,1-.39.69l-2.38,1.42Z"/>
  <path class="cls-1" d="M22.11,5.48v3H9.91v-3Z"/>
  <path class="cls-1" d="M9.9,12.4V9.51H22.1V12.4Q16,12.68,9.9,12.4Z"/>
  <path class="cls-1" d="M2.22,11.68a36.67,36.67,0,0,1,6.63-.84V12.5A38.89,38.89,0,0,1,2.22,11.68Z"/>
  <path class="cls-1" d="M23.17,10.84a35.9,35.9,0,0,1,6.64.84,37.9,37.9,0,0,1-6.64.82Z"/>
  <path class="cls-1" d="M7.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,6.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,7.8,8.55Z"/>
  <path class="cls-1" d="M24.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,23.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,24.8,8.55Z"/>
  <circle class="cls-3" cx="11.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="11.77" cy="11.03" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="11.03" r="0.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-3{fill:#fff;}.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>optimizeFilterIconGreyG</title>
  <path class="cls-1" d="M1.72,12.63c.46.1.88.23,1.32.29,1.29.17,2.59.35,3.89.46,3,.26,5.92.35,8.89.32,2.7,0,5.4-.12,8.1-.24,1.47-.07,2.94-.25,4.4-.42.64-.08,1.28-.26,1.95-.4v.72c0,.65,0,1.29,0,1.94a.37.37,0,0,1-.33.44c-.73.14-1.46.32-2.21.42a101.5,101.5,0,0,1-14.38.58,77.55,77.55,0,0,1-8.75-.54c-.88-.1-1.74-.32-2.61-.49-.21,0-.29-.15-.28-.38C1.73,14.44,1.72,13.55,1.72,12.63Z"/>
  <path class="cls-1" d="M14,29.38v-.33c0-1.43,0-2.86,0-4.29a2.53,2.53,0,0,0-1.14-2.21C10.63,21,8.42,19.37,6.2,17.77a2.24,2.24,0,0,1-.35-.29,134.94,134.94,0,0,0,19.39,0l-.26.2-6.85,4.93A2.41,2.41,0,0,0,17,24.66c0,.82,0,1.65,0,2.47a.69.69,0,0,1-.39.69l-2.38,1.42Z"/>
  <path class="cls-1" d="M22.11,5.48v3H9.91v-3Z"/>
  <path class="cls-1" d="M9.9,12.4V9.51H22.1V12.4Q16,12.68,9.9,12.4Z"/>
  <path class="cls-1" d="M2.22,11.68a36.67,36.67,0,0,1,6.63-.84V12.5A38.89,38.89,0,0,1,2.22,11.68Z"/>
  <path class="cls-1" d="M23.17,10.84a35.9,35.9,0,0,1,6.64.84,37.9,37.9,0,0,1-6.64.82Z"/>
  <path class="cls-1" d="M7.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,6.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,7.8,8.55Z"/>
  <path class="cls-1" d="M24.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,23.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,24.8,8.55Z"/>
  <circle class="cls-3" cx="11.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="11.77" cy="11.03" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="11.03" r="0.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-3{fill:#fff;}.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>optimizeFilterIconOnG</title>
  <path class="cls-1" d="M1.72,12.63c.46.1.88.23,1.32.29,1.29.17,2.59.35,3.89.46,3,.26,5.92.35,8.89.32,2.7,0,5.4-.12,8.1-.24,1.47-.07,2.94-.25,4.4-.42.64-.08,1.28-.26,1.95-.4v.72c0,.65,0,1.29,0,1.94a.37.37,0,0,1-.33.44c-.73.14-1.46.32-2.21.42a101.5,101.5,0,0,1-14.38.58,77.55,77.55,0,0,1-8.75-.54c-.88-.1-1.74-.32-2.61-.49-.21,0-.29-.15-.28-.38C1.73,14.44,1.72,13.55,1.72,12.63Z"/>
  <path class="cls-1" d="M14,29.38v-.33c0-1.43,0-2.86,0-4.29a2.53,2.53,0,0,0-1.14-2.21C10.63,21,8.42,19.37,6.2,17.77a2.24,2.24,0,0,1-.35-.29,134.94,134.94,0,0,0,19.39,0l-.26.2-6.85,4.93A2.41,2.41,0,0,0,17,24.66c0,.82,0,1.65,0,2.47a.69.69,0,0,1-.39.69l-2.38,1.42Z"/>
  <path class="cls-1" d="M22.11,5.48v3H9.91v-3Z"/>
  <path class="cls-1" d="M9.9,12.4V9.51H22.1V12.4Q16,12.68,9.9,12.4Z"/>
  <path class="cls-1" d="M2.22,11.68a36.67,36.67,0,0,1,6.63-.84V12.5A38.89,38.89,0,0,1,2.22,11.68Z"/>
  <path class="cls-1" d="M23.17,10.84a35.9,35.9,0,0,1,6.64.84,37.9,37.9,0,0,1-6.64.82Z"/>
  <path class="cls-1" d="M7.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,6.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,7.8,8.55Z"/>
  <path class="cls-1" d="M24.8,8.55V9.63a.45.45,0,0,1,0,.11.27.27,0,0,1-.3.22.28.28,0,0,1-.25-.29V8.55A.58.58,0,0,1,23.67,8V7.45a.57.57,0,0,1,.53-.59h0V3a.61.61,0,0,1,0-.14.29.29,0,0,1,.3-.2.28.28,0,0,1,.25.26v4h.1a.58.58,0,0,1,.47.56v.51A.57.57,0,0,1,24.8,8.55Z"/>
  <circle class="cls-3" cx="11.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="6.99" r="0.5"/>
  <circle class="cls-3" cx="11.77" cy="11.03" r="0.5"/>
  <circle class="cls-3" cx="13.77" cy="11.03" r="0.5"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>optimizeIconOffG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <rect class="cls-2" x="13.77" y="20.4" width="1.42" height="4.97" transform="translate(-11.94 16.94) rotate(-45)"/>
  <rect class="cls-2" x="11.93" y="22.24" width="1.42" height="4.97" transform="translate(-13.78 16.18) rotate(-45)"/>
  <polygon class="cls-2" points="34.45 30.29 6.97 30.29 6.97 2.81 6.05 2.81 6.05 31.21 34.45 31.21 34.45 30.29"/>
  <polygon class="cls-2" points="17.73 23.15 23.89 16.98 25.86 18.89 27.91 9.41 18.48 11.51 20.41 13.44 14.22 19.63 17.73 23.15"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>optimizeIconGreyG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <rect class="cls-2" x="13.77" y="20.4" width="1.42" height="4.97" transform="translate(-11.94 16.94) rotate(-45)"/>
  <rect class="cls-2" x="11.93" y="22.24" width="1.42" height="4.97" transform="translate(-13.78 16.18) rotate(-45)"/>
  <polygon class="cls-2" points="34.45 30.29 6.97 30.29 6.97 2.81 6.05 2.81 6.05 31.21 34.45 31.21 34.45 30.29"/>
  <polygon class="cls-2" points="17.73 23.15 23.89 16.98 25.86 18.89 27.91 9.41 18.48 11.51 20.41 13.44 14.22 19.63 17.73 23.15"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b53;}</style>
  </defs>
  <title>optimizeIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <rect class="cls-1" x="13.77" y="20.4" width="1.42" height="4.97" transform="translate(-11.94 16.94) rotate(-45)"/>
  <rect class="cls-1" x="11.93" y="22.24" width="1.42" height="4.97" transform="translate(-13.78 16.18) rotate(-45)"/>
  <polygon class="cls-1" points="34.45 30.29 6.97 30.29 6.97 2.81 6.05 2.81 6.05 31.21 34.45 31.21 34.45 30.29"/>
  <polygon class="cls-1" points="17.73 23.15 23.89 16.98 25.86 18.89 27.91 9.41 18.48 11.51 20.41 13.44 14.22 19.63 17.73 23.15"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>propsIconOffG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M21.49,21.15a6.56,6.56,0,0,0,8.17-4.4,6.65,6.65,0,0,0,0-3.75l-3.22,3.22c-.93.93-2.7.67-4-.58s-1.51-3-.58-4L25,8.48a6.57,6.57,0,0,0-8,8.09l-5.89,5.89c-.93.93-.67,2.7.58,3.95s3,1.51,4,.58Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>propsIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M21.49,21.15a6.56,6.56,0,0,0,8.17-4.4,6.65,6.65,0,0,0,0-3.75l-3.22,3.22c-.93.93-2.7.67-4-.58s-1.51-3-.58-4L25,8.48a6.57,6.57,0,0,0-8,8.09l-5.89,5.89c-.93.93-.67,2.7.58,3.95s3,1.51,4,.58Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>propsIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M21.49,21.15a6.56,6.56,0,0,0,8.17-4.4,6.65,6.65,0,0,0,0-3.75l-3.22,3.22c-.93.93-2.7.67-4-.58s-1.51-3-.58-4L25,8.48a6.57,6.57,0,0,0-8,8.09l-5.89,5.89c-.93.93-.67,2.7.58,3.95s3,1.51,4,.58Z" transform="translate(-0.03 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:#424b53;}.cls-2{fill:none;}</style>
  </defs>
  <title>restrictIconOffG</title>
  <path class="cls-1" d="M16.91,18.07a6.31,6.31,0,0,0,.72-3c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.32,6.32,0,0,0,.72,3c-1.71.91-3.86,3.37-3.86,5.15A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.45,18.62,19,16.91,18.07Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.25,13.28,11.25ZM19,23.49H7.54a.25.25,0,0,1-.25-.25c0-1.3,2.06-3.41,3.27-3.93a3.89,3.89,0,0,0,2.72,1.17A3.85,3.85,0,0,0,16,19.29c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.49Z"/>
  <path class="cls-1" d="M24.41,15.53a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.32,4.32,0,0,0-3.89,3,.74.74,0,1,0,1.38.56,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.29,2.29,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06.28.28,0,0,0,.1.09,3.78,3.78,0,0,0,2.59,1,4,4,0,0,0,2.76-1.28l0,0h0c1.21.5,3.18,2.62,3.18,3.93a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.76,1.76,0,0,0,1.75-1.75C28.27,18.93,26.13,16.39,24.41,15.53Z"/>
  <path class="cls-1" d="M34.26,21.12h0A3.24,3.24,0,1,0,34.88,23,3.26,3.26,0,0,0,34.26,21.12Zm-2.66-.24a2.13,2.13,0,0,1,1.3.47l-3.3,2.31a2.07,2.07,0,0,1-.12-.65,2.13,2.13,0,0,1,2.11-2.13Zm0,4.22a2.17,2.17,0,0,1-1.36-.5l3.33-2.31a1.85,1.85,0,0,1,.13.7A2.11,2.11,0,0,1,31.6,25.1Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:#424b53;}.cls-2{fill:none;}</style>
  </defs>
  <title>restrictIconGreyG</title>
  <path class="cls-1" d="M16.91,18.07a6.31,6.31,0,0,0,.72-3c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.32,6.32,0,0,0,.72,3c-1.71.91-3.86,3.37-3.86,5.15A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.45,18.62,19,16.91,18.07Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.25,13.28,11.25ZM19,23.49H7.54a.25.25,0,0,1-.25-.25c0-1.3,2.06-3.41,3.27-3.93a3.89,3.89,0,0,0,2.72,1.17A3.85,3.85,0,0,0,16,19.29c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.49Z"/>
  <path class="cls-1" d="M24.41,15.53a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.32,4.32,0,0,0-3.89,3,.74.74,0,1,0,1.38.56,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.29,2.29,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06.28.28,0,0,0,.1.09,3.78,3.78,0,0,0,2.59,1,4,4,0,0,0,2.76-1.28l0,0h0c1.21.5,3.18,2.62,3.18,3.93a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.76,1.76,0,0,0,1.75-1.75C28.27,18.93,26.13,16.39,24.41,15.53Z"/>
  <path class="cls-1" d="M34.26,21.12h0A3.24,3.24,0,1,0,34.88,23,3.26,3.26,0,0,0,34.26,21.12Zm-2.66-.24a2.13,2.13,0,0,1,1.3.47l-3.3,2.31a2.07,2.07,0,0,1-.12-.65,2.13,2.13,0,0,1,2.11-2.13Zm0,4.22a2.17,2.17,0,0,1-1.36-.5l3.33-2.31a1.85,1.85,0,0,1,.13.7A2.11,2.11,0,0,1,31.6,25.1Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-1{fill:#424b53;}.cls-2{fill:none;}</style>
  </defs>
  <title>restrictIconOnG</title>
  <path class="cls-1" d="M16.91,18.07a6.31,6.31,0,0,0,.72-3c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.32,6.32,0,0,0,.72,3c-1.71.91-3.86,3.37-3.86,5.15A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.45,18.62,19,16.91,18.07Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.25,13.28,11.25ZM19,23.49H7.54a.25.25,0,0,1-.25-.25c0-1.3,2.06-3.41,3.27-3.93a3.89,3.89,0,0,0,2.72,1.17A3.85,3.85,0,0,0,16,19.29c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.49Z"/>
  <path class="cls-1" d="M24.41,15.53a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.32,4.32,0,0,0-3.89,3,.74.74,0,1,0,1.38.56,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.29,2.29,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06.28.28,0,0,0,.1.09,3.78,3.78,0,0,0,2.59,1,4,4,0,0,0,2.76-1.28l0,0h0c1.21.5,3.18,2.62,3.18,3.93a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.76,1.76,0,0,0,1.75-1.75C28.27,18.93,26.13,16.39,24.41,15.53Z"/>
  <path class="cls-1" d="M34.26,21.12h0A3.24,3.24,0,1,0,34.88,23,3.26,3.26,0,0,0,34.26,21.12Zm-2.66-.24a2.13,2.13,0,0,1,1.3.47l-3.3,2.31a2.07,2.07,0,0,1-.12-.65,2.13,2.13,0,0,1,2.11-2.13Zm0,4.22a2.17,2.17,0,0,1-1.36-.5l3.33-2.31a1.85,1.85,0,0,1,.13.7A2.11,2.11,0,0,1,31.6,25.1Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 32 32">
  <title>SampleStoryOffG</title>
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#555;}</style>
  </defs>
  <rect class="cls-1" x="3.64" y="3.92" width="25.72" height="27.93"/>
  <path class="cls-2" d="M8.8,8.29A1.7,1.7,0,0,0,7.1,10V24.12a1.8,1.8,0,0,0,1.8,1.8H20.31A2.92,2.92,0,0,0,23.23,23V8.29Zm3.6,15.49H8.47a.5.5,0,0,1,0-1H12.4a.5.5,0,1,1,0,1Zm4-1.71H8.47A.49.49,0,0,1,8,21.58a.5.5,0,0,1,.49-.5h7.91a.51.51,0,0,1,.5.5A.5.5,0,0,1,16.38,22.07Zm.4-4a2,2,0,0,1-.79.71,2.57,2.57,0,0,1-1.21.27,2.86,2.86,0,0,1-1.2-.23,1.88,1.88,0,0,1-.83-.66,1.6,1.6,0,0,1-.31-1H13.6a1,1,0,0,0,.34.7,1.21,1.21,0,0,0,.84.27,1.29,1.29,0,0,0,.87-.27A.86.86,0,0,0,16,17.2a.77.77,0,0,0-.19-.55,1.44,1.44,0,0,0-.49-.32c-.19-.07-.46-.16-.8-.25a8.16,8.16,0,0,1-1.05-.34,1.71,1.71,0,0,1-.68-.55,1.55,1.55,0,0,1-.29-1,1.78,1.78,0,0,1,.29-1,1.82,1.82,0,0,1,.79-.65,2.78,2.78,0,0,1,1.19-.23,2.47,2.47,0,0,1,1.57.48A1.77,1.77,0,0,1,17,14.12h-1.2a.89.89,0,0,0-.34-.62,1.26,1.26,0,0,0-.83-.25,1.13,1.13,0,0,0-.75.24.8.8,0,0,0-.3.68.68.68,0,0,0,.19.5,1.19,1.19,0,0,0,.47.3,7,7,0,0,0,.77.25,7.76,7.76,0,0,1,1.07.36,1.73,1.73,0,0,1,.7.56,1.55,1.55,0,0,1,.29,1A1.83,1.83,0,0,1,16.78,18.09Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b52;}</style>
  </defs>
  <title>shareIconOffG</title>
  <path class="cls-1" d="M16.91,18.08a6.31,6.31,0,0,0,.72-2.95c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.28,6.28,0,0,0,.72,2.95c-1.71.92-3.86,3.37-3.86,5.16A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.46,18.62,19,16.91,18.08Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.26,13.28,11.26ZM19,23.5H7.54a.25.25,0,0,1-.25-.25c0-1.31,2.06-3.42,3.27-3.94a3.73,3.73,0,0,0,5.45,0c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.5Z"/>
  <path class="cls-1" d="M24.41,15.54a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.3,4.3,0,0,0-3.89,3,.74.74,0,0,0,.41,1,.75.75,0,0,0,1-.41,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.33,2.33,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06l.1.09a3.83,3.83,0,0,0,2.59,1.05,4,4,0,0,0,2.76-1.29l0,0h0c1.21.51,3.18,2.63,3.18,3.94a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.77,1.77,0,0,0,1.75-1.75C28.27,18.94,26.13,16.4,24.41,15.54Z"/>
  <path class="cls-1" d="M34.49,20.29A.81.81,0,0,0,34,20l-3.69-.83a.77.77,0,0,0-.87.64.78.78,0,0,0,.54.84l1.91.43-5.39,3.4a.76.76,0,0,0,.41,1.4.72.72,0,0,0,.4-.12l5.38-3.41-.42,1.91a.73.73,0,0,0,.54.89h0a.43.43,0,0,0,.16,0,.77.77,0,0,0,.74-.59l.83-3.68A.75.75,0,0,0,34.49,20.29Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b52;}</style>
  </defs>
  <title>shareIconGreyG</title>
  <path class="cls-1" d="M16.91,18.08a6.31,6.31,0,0,0,.72-2.95c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.28,6.28,0,0,0,.72,2.95c-1.71.92-3.86,3.37-3.86,5.16A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.46,18.62,19,16.91,18.08Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.26,13.28,11.26ZM19,23.5H7.54a.25.25,0,0,1-.25-.25c0-1.31,2.06-3.42,3.27-3.94a3.73,3.73,0,0,0,5.45,0c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.5Z"/>
  <path class="cls-1" d="M24.41,15.54a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.3,4.3,0,0,0-3.89,3,.74.74,0,0,0,.41,1,.75.75,0,0,0,1-.41,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.33,2.33,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06l.1.09a3.83,3.83,0,0,0,2.59,1.05,4,4,0,0,0,2.76-1.29l0,0h0c1.21.51,3.18,2.63,3.18,3.94a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.77,1.77,0,0,0,1.75-1.75C28.27,18.94,26.13,16.4,24.41,15.54Z"/>
  <path class="cls-1" d="M34.49,20.29A.81.81,0,0,0,34,20l-3.69-.83a.77.77,0,0,0-.87.64.78.78,0,0,0,.54.84l1.91.43-5.39,3.4a.76.76,0,0,0,.41,1.4.72.72,0,0,0,.4-.12l5.38-3.41-.42,1.91a.73.73,0,0,0,.54.89h0a.43.43,0,0,0,.16,0,.77.77,0,0,0,.74-.59l.83-3.68A.75.75,0,0,0,34.49,20.29Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 46.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b52;}</style>
  </defs>
  <title>shareIconOnG</title>
  <path class="cls-1" d="M16.91,18.08a6.31,6.31,0,0,0,.72-2.95c0-3-1.95-5.37-4.35-5.37s-4.35,2.41-4.35,5.37a6.28,6.28,0,0,0,.72,2.95c-1.71.92-3.86,3.37-3.86,5.16A1.74,1.74,0,0,0,7.53,25H19a1.74,1.74,0,0,0,1.75-1.73h0C20.76,21.46,18.62,19,16.91,18.08Zm-3.63-6.82c1.57,0,2.85,1.73,2.85,3.87S14.85,19,13.28,19s-2.89-1.73-2.89-3.86S11.7,11.26,13.28,11.26ZM19,23.5H7.54a.25.25,0,0,1-.25-.25c0-1.31,2.06-3.42,3.27-3.94a3.73,3.73,0,0,0,5.45,0c1.22.53,3.27,2.65,3.27,3.95A.25.25,0,0,1,19,23.5Z"/>
  <path class="cls-1" d="M24.41,15.54a6.29,6.29,0,0,0,.7-2.91c0-3-1.95-5.36-4.35-5.36a4.3,4.3,0,0,0-3.89,3,.74.74,0,0,0,.41,1,.75.75,0,0,0,1-.41,2.88,2.88,0,0,1,2.51-2c1.57,0,2.85,1.74,2.85,3.87s-1.28,3.87-2.85,3.87a2.33,2.33,0,0,1-1.58-.66.76.76,0,0,0-1.06,0,.75.75,0,0,0,0,1.06l.1.09a3.83,3.83,0,0,0,2.59,1.05,4,4,0,0,0,2.76-1.29l0,0h0c1.21.51,3.18,2.63,3.18,3.94a.25.25,0,0,1-.25.25H22.08a.75.75,0,0,0,0,1.5H26.5a1.77,1.77,0,0,0,1.75-1.75C28.27,18.94,26.13,16.4,24.41,15.54Z"/>
  <path class="cls-1" d="M34.49,20.29A.81.81,0,0,0,34,20l-3.69-.83a.77.77,0,0,0-.87.64.78.78,0,0,0,.54.84l1.91.43-5.39,3.4a.76.76,0,0,0,.41,1.4.72.72,0,0,0,.4-.12l5.38-3.41-.42,1.91a.73.73,0,0,0,.54.89h0a.43.43,0,0,0,.16,0,.77.77,0,0,0,.74-.59l.83-3.68A.75.75,0,0,0,34.49,20.29Z"/>
  <rect class="cls-2" width="40.5" height="46.5"/>
</svg>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <defs>
    <style type="text/css">&#xD;&#xD;
	.cls-1{fill:#FFFFFF;}&#xD;&#xD;
</style>
  </defs>
  <title>storyHeaderIconGreyG</title>
  <path id="Path_16_00000026845227152939048290000012696720214093092504_" class="cls-1" d="M23.8,23.9h-0.7V6.1&#xA;&#x9;c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.1-0.9,0.6h12v18.6h-0.7V7.4c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0&#xA;&#x9;C9.3,7.4,9.1,7.5,9,8.1h12v18.6H8.2c0-0.1,0-0.3,0-0.4c0-6,0-12,0-18C8.1,7.5,8.7,6.8,9.4,6.7c0.1,0,0.2-0.1,0.2-0.2&#xA;&#x9;c0.2-0.7,0.8-1.2,1.6-1.1c2.5,0,4.9,0,7.4,0h5.1V23.9z"/>
</svg>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <defs>
    <style type="text/css">&#xD;&#xD;
	.cls-1{fill:#FFFFFF;}&#xD;&#xD;
</style>
  </defs>
  <title>storyHeaderIconOffG</title>
  <path id="Path_16_00000026845227152939048290000012696720214093092504_" class="cls-1" d="M23.8,23.9h-0.7V6.1&#xA;&#x9;c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.1-0.9,0.6h12v18.6h-0.7V7.4c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0&#xA;&#x9;C9.3,7.4,9.1,7.5,9,8.1h12v18.6H8.2c0-0.1,0-0.3,0-0.4c0-6,0-12,0-18C8.1,7.5,8.7,6.8,9.4,6.7c0.1,0,0.2-0.1,0.2-0.2&#xA;&#x9;c0.2-0.7,0.8-1.2,1.6-1.1c2.5,0,4.9,0,7.4,0h5.1V23.9z"/>
</svg>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <defs>
    <style type="text/css">&#xD;&#xD;
	.cls-1{fill:#FFFFFF;}&#xD;&#xD;
</style>
  </defs>
  <title>storyHeaderIconOnG</title>
  <path id="Path_16_00000026845227152939048290000012696720214093092504_" class="cls-1" d="M23.8,23.9h-0.7V6.1&#xA;&#x9;c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.1-0.9,0.6h12v18.6h-0.7V7.4c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0&#xA;&#x9;C9.3,7.4,9.1,7.5,9,8.1h12v18.6H8.2c0-0.1,0-0.3,0-0.4c0-6,0-12,0-18C8.1,7.5,8.7,6.8,9.4,6.7c0.1,0,0.2-0.1,0.2-0.2&#xA;&#x9;c0.2-0.7,0.8-1.2,1.6-1.1c2.5,0,4.9,0,7.4,0h5.1V23.9z"/>
</svg>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <defs>
    <style type="text/css">&#xD;&#xD;
	.cls-1{fill:#FFFFFF;}&#xD;&#xD;
</style>
  </defs>
  <title>storyOffG</title>
  <path id="Path_16_00000026845227152939048290000012696720214093092504_" class="cls-1" d="M23.8,23.9h-0.7V6.1&#xA;&#x9;c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.1-0.9,0.6h12v18.6h-0.7V7.4c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0&#xA;&#x9;C9.3,7.4,9.1,7.5,9,8.1h12v18.6H8.2c0-0.1,0-0.3,0-0.4c0-6,0-12,0-18C8.1,7.5,8.7,6.8,9.4,6.7c0.1,0,0.2-0.1,0.2-0.2&#xA;&#x9;c0.2-0.7,0.8-1.2,1.6-1.1c2.5,0,4.9,0,7.4,0h5.1V23.9z"/>
</svg>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <defs>
    <style type="text/css">&#xD;&#xD;
	.cls-1{fill:#FFFFFF;}&#xD;&#xD;
</style>
  </defs>
  <title>storyGreyG</title>
  <path id="Path_16_00000026845227152939048290000012696720214093092504_" class="cls-1" d="M23.8,23.9h-0.7V6.1&#xA;&#x9;c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.1-0.9,0.6h12v18.6h-0.7V7.4c-0.2,0-0.3,0-0.4,0c-3.8,0-7.6,0-11.4,0&#xA;&#x9;C9.3,7.4,9.1,7.5,9,8.1h12v18.6H8.2c0-0.1,0-0.3,0-0.4c0-6,0-12,0-18C8.1,7.5,8.7,6.8,9.4,6.7c0.1,0,0.2-0.1,0.2-0.2&#xA;&#x9;c0.2-0.7,0.8-1.2,1.6-1.1c2.5,0,4.9,0,7.4,0h5.1V23.9z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <defs>
    <style>.cls-1{fill:#252525;}</style>
  </defs>
  <title>storyOnG</title>
  <path class="cls-1" d="M.84.84V31.16H31.16V.84Zm23,23.09h-.68V6.05l-.44,0H11.29c-.61,0-.79.13-.88.64h12V25.29H21.7V7.4l-.41,0H9.87c-.61,0-.81.14-.9.68H21V26.69H8.25c0-.13,0-.27,0-.41v-18a1.43,1.43,0,0,1,1.2-1.6.41.41,0,0,0,.24-.24,1.44,1.44,0,0,1,1.58-1.13H23.79Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>switchDataIconOffG</title>
  <path class="cls-1" d="M5,11.08H25.25A2.88,2.88,0,0,0,28.12,8.2V7.84A2.88,2.88,0,0,0,25.25,5H5A2.87,2.87,0,0,0,2.14,7.84V8.2A2.87,2.87,0,0,0,5,11.08Zm6.3-4.59A1.53,1.53,0,1,1,9.78,8,1.53,1.53,0,0,1,11.31,6.49Zm-4.59,0A1.53,1.53,0,1,1,5.19,8,1.52,1.52,0,0,1,6.72,6.49Zm18.94,9.89a6.16,6.16,0,0,1,2.28.43,2.88,2.88,0,0,0,.18-1v-.36a2.88,2.88,0,0,0-2.87-2.88H5a2.87,2.87,0,0,0-2.87,2.88v.36A2.87,2.87,0,0,0,5,18.72H20.74A6.34,6.34,0,0,1,25.66,16.38Zm-18.94.81a1.53,1.53,0,1,1,1.53-1.53A1.53,1.53,0,0,1,6.72,17.19Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,17.19Zm8.51,3.06H5a2.87,2.87,0,0,0-2.87,2.87v.37A2.87,2.87,0,0,0,5,26.36H20.48a6.31,6.31,0,0,1-.66-6.11ZM6.72,24.83A1.53,1.53,0,1,1,8.25,23.3,1.53,1.53,0,0,1,6.72,24.83Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,24.83Zm19.06-2.38a3.07,3.07,0,0,0-2.93-2.51c-.81,0-1.63,0-2.45,0h-.12v-.12c0-.38,0-.77,0-1.15a.29.29,0,0,0-.26-.32.35.35,0,0,0-.27.11l-2.1,2a.31.31,0,0,0,0,.52l2.1,2a.31.31,0,0,0,.36.09.32.32,0,0,0,.18-.32c0-.38,0-.76,0-1.13v-.11h.07c.95,0,1.9,0,2.85,0a2.09,2.09,0,0,1,2,2c0,.2.08.32.24.35s.26,0,.35-.21h0v-.89Zm-3.39,0a.28.28,0,0,0-.34-.08.28.28,0,0,0-.18.3V23.9h-.08c-.94,0-1.88,0-2.82,0a2,2,0,0,1-1.83-1.11,2.13,2.13,0,0,1-.24-1,.29.29,0,0,0-.29-.3.28.28,0,0,0-.3.27A6.81,6.81,0,0,0,21,23a3.06,3.06,0,0,0,2.92,2.5c.82,0,1.65,0,2.47,0h.12v1.28a.29.29,0,0,0,.28.31A.32.32,0,0,0,27,27l2.11-2a.3.3,0,0,0,0-.49Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>switchDataIconGreyG</title>
  <path class="cls-1" d="M5,11.08H25.25A2.88,2.88,0,0,0,28.12,8.2V7.84A2.88,2.88,0,0,0,25.25,5H5A2.87,2.87,0,0,0,2.14,7.84V8.2A2.87,2.87,0,0,0,5,11.08Zm6.3-4.59A1.53,1.53,0,1,1,9.78,8,1.53,1.53,0,0,1,11.31,6.49Zm-4.59,0A1.53,1.53,0,1,1,5.19,8,1.52,1.52,0,0,1,6.72,6.49Zm18.94,9.89a6.16,6.16,0,0,1,2.28.43,2.88,2.88,0,0,0,.18-1v-.36a2.88,2.88,0,0,0-2.87-2.88H5a2.87,2.87,0,0,0-2.87,2.88v.36A2.87,2.87,0,0,0,5,18.72H20.74A6.34,6.34,0,0,1,25.66,16.38Zm-18.94.81a1.53,1.53,0,1,1,1.53-1.53A1.53,1.53,0,0,1,6.72,17.19Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,17.19Zm8.51,3.06H5a2.87,2.87,0,0,0-2.87,2.87v.37A2.87,2.87,0,0,0,5,26.36H20.48a6.31,6.31,0,0,1-.66-6.11ZM6.72,24.83A1.53,1.53,0,1,1,8.25,23.3,1.53,1.53,0,0,1,6.72,24.83Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,24.83Zm19.06-2.38a3.07,3.07,0,0,0-2.93-2.51c-.81,0-1.63,0-2.45,0h-.12v-.12c0-.38,0-.77,0-1.15a.29.29,0,0,0-.26-.32.35.35,0,0,0-.27.11l-2.1,2a.31.31,0,0,0,0,.52l2.1,2a.31.31,0,0,0,.36.09.32.32,0,0,0,.18-.32c0-.38,0-.76,0-1.13v-.11h.07c.95,0,1.9,0,2.85,0a2.09,2.09,0,0,1,2,2c0,.2.08.32.24.35s.26,0,.35-.21h0v-.89Zm-3.39,0a.28.28,0,0,0-.34-.08.28.28,0,0,0-.18.3V23.9h-.08c-.94,0-1.88,0-2.82,0a2,2,0,0,1-1.83-1.11,2.13,2.13,0,0,1-.24-1,.29.29,0,0,0-.29-.3.28.28,0,0,0-.3.27A6.81,6.81,0,0,0,21,23a3.06,3.06,0,0,0,2.92,2.5c.82,0,1.65,0,2.47,0h.12v1.28a.29.29,0,0,0,.28.31A.32.32,0,0,0,27,27l2.11-2a.3.3,0,0,0,0-.49Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>switchDataIconOnG</title>
  <path class="cls-1" d="M5,11.08H25.25A2.88,2.88,0,0,0,28.12,8.2V7.84A2.88,2.88,0,0,0,25.25,5H5A2.87,2.87,0,0,0,2.14,7.84V8.2A2.87,2.87,0,0,0,5,11.08Zm6.3-4.59A1.53,1.53,0,1,1,9.78,8,1.53,1.53,0,0,1,11.31,6.49Zm-4.59,0A1.53,1.53,0,1,1,5.19,8,1.52,1.52,0,0,1,6.72,6.49Zm18.94,9.89a6.16,6.16,0,0,1,2.28.43,2.88,2.88,0,0,0,.18-1v-.36a2.88,2.88,0,0,0-2.87-2.88H5a2.87,2.87,0,0,0-2.87,2.88v.36A2.87,2.87,0,0,0,5,18.72H20.74A6.34,6.34,0,0,1,25.66,16.38Zm-18.94.81a1.53,1.53,0,1,1,1.53-1.53A1.53,1.53,0,0,1,6.72,17.19Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,17.19Zm8.51,3.06H5a2.87,2.87,0,0,0-2.87,2.87v.37A2.87,2.87,0,0,0,5,26.36H20.48a6.31,6.31,0,0,1-.66-6.11ZM6.72,24.83A1.53,1.53,0,1,1,8.25,23.3,1.53,1.53,0,0,1,6.72,24.83Zm4.59,0a1.53,1.53,0,1,1,1.53-1.53A1.52,1.52,0,0,1,11.31,24.83Zm19.06-2.38a3.07,3.07,0,0,0-2.93-2.51c-.81,0-1.63,0-2.45,0h-.12v-.12c0-.38,0-.77,0-1.15a.29.29,0,0,0-.26-.32.35.35,0,0,0-.27.11l-2.1,2a.31.31,0,0,0,0,.52l2.1,2a.31.31,0,0,0,.36.09.32.32,0,0,0,.18-.32c0-.38,0-.76,0-1.13v-.11h.07c.95,0,1.9,0,2.85,0a2.09,2.09,0,0,1,2,2c0,.2.08.32.24.35s.26,0,.35-.21h0v-.89Zm-3.39,0a.28.28,0,0,0-.34-.08.28.28,0,0,0-.18.3V23.9h-.08c-.94,0-1.88,0-2.82,0a2,2,0,0,1-1.83-1.11,2.13,2.13,0,0,1-.24-1,.29.29,0,0,0-.29-.3.28.28,0,0,0-.3.27A6.81,6.81,0,0,0,21,23a3.06,3.06,0,0,0,2.92,2.5c.82,0,1.65,0,2.47,0h.12v1.28a.29.29,0,0,0,.28.31A.32.32,0,0,0,27,27l2.11-2a.3.3,0,0,0,0-.49Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <title>taskHeaderIconOffG</title>
  <defs>
    <style>.cls-1{fill:#4c4c4c;}.cls-2{fill:#fff;}</style>
  </defs>
  <path id="Icon_awesome-tasks" data-name="Icon awesome-tasks" class="cls-1" d="M10.24,5.49a.6.6,0,0,0-.84,0h0L6.25,8.62,5.13,7.53a.6.6,0,0,0-.84,0h0l-.78.78a.6.6,0,0,0,0,.84h0l2.36,2.34a.63.63,0,0,0,.87,0l.77-.77,3.57-3.57a.6.6,0,0,0,0-.84Zm0,7.87a.6.6,0,0,0-.84,0h0L6.25,16.52l-1.12-1.1a.6.6,0,0,0-.84,0h0l-.78.78a.6.6,0,0,0,0,.84h0l2.35,2.35a.63.63,0,0,0,.87,0l.78-.78L11.08,15a.6.6,0,0,0,0-.84ZM6.5,21.94a2.37,2.37,0,0,0,0,4.74h0a2.37,2.37,0,1,0,0-4.74Zm21.37.79H13.63a.79.79,0,0,0-.8.79V25.1a.79.79,0,0,0,.8.79H27.87a.79.79,0,0,0,.79-.79V23.52a.79.79,0,0,0-.79-.79Zm0-15.83H13.63a.79.79,0,0,0-.8.79V9.27a.8.8,0,0,0,.8.8H27.87a.79.79,0,0,0,.79-.8V7.69a.79.79,0,0,0-.79-.79Zm0,7.91H13.63a.8.8,0,0,0-.8.79v1.59a.8.8,0,0,0,.8.79H27.87a.79.79,0,0,0,.79-.79h0V15.61a.79.79,0,0,0-.79-.8Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>transformIconOffG</title>
  <path class="cls-1" d="M14,25.37H3.29a1.67,1.67,0,0,1-1.83-1.82q0-9.39,0-18.77A1.67,1.67,0,0,1,3.27,3H13.4a.87.87,0,0,1,.67.29l3,3.17a.6.6,0,0,0,.5.22h8.26a1,1,0,0,1,.81.34c1.23,1.3,2.46,2.59,3.67,3.9a1,1,0,0,1,.21.63c0,4,0,7.94,0,11.91,0,1.25,0,2.51,0,3.76A1.68,1.68,0,0,1,28.69,29H15.83A1.67,1.67,0,0,1,14,27.18Zm3.17-6.05H14.48c-.36,0-.5-.15-.5-.5,0-.64,0-1.29,0-1.93,0-.41.13-.55.55-.55h2.62V15.7H12.81v1.58c0,.23,0,.45-.25.57s-.41,0-.58-.16c-1.33-1-2.67-2.05-4-3.09-.39-.3-.39-.57,0-.87l3.83-3c.21-.16.41-.43.75-.27s.24.49.24.76c0,.47,0,.93,0,1.4h4.34V8H13.92c-.65,0-.73-.09-.73-.72V3.83H3.39c-.82,0-1.08.26-1.08,1.07V23.54c0,.66.3,1,.92,1h13a.81.81,0,0,0,.91-.91v-4C17.17,19.53,17.16,19.44,17.15,19.32ZM25.7,7.51H18v8.8h1.15V14.66c0-.21,0-.44.26-.49a.68.68,0,0,1,.52.11c1.37,1,2.72,2.08,4.07,3.13.36.29.36.56,0,.85l-1.25,1L20,21.4c-.28.21-.56.2-.69,0a.71.71,0,0,1-.09-.38c0-.55,0-1.09,0-1.65H18v.35c0,1.34,0,2.69,0,4a1.65,1.65,0,0,1-1.63,1.64H14.83v1.79c0,.74.28,1,1,1H28.67c.73,0,1-.29,1-1V12c0-.1,0-.21,0-.33h-3.4c-.44,0-.57-.13-.57-.57V7.51Z"/>
  <path class="cls-1" d="M6.53,17.9H4.67c-.35,0-.55-.17-.55-.44s.2-.42.53-.42h3.8c.35,0,.55.16.54.44s-.2.41-.53.42Z"/>
  <path class="cls-1" d="M6.56,11.3H4.64c-.34,0-.52-.16-.52-.43s.19-.42.5-.43H8.51c.3,0,.49.19.48.44s-.19.42-.5.42Z"/>
  <path class="cls-1" d="M5.55,14.6h-1a.42.42,0,0,1-.48-.4.44.44,0,0,1,.48-.45H6.52a.44.44,0,0,1,.47.43.43.43,0,0,1-.47.42Z"/>
  <path class="cls-1" d="M25.44,15h-1.9c-.33,0-.52-.15-.53-.41s.19-.44.54-.44h3.79c.34,0,.53.15.54.42s-.19.43-.54.43Z"/>
  <path class="cls-1" d="M25.44,20.71c.64,0,1.28,0,1.92,0,.34,0,.52.16.52.43a.44.44,0,0,1-.5.42H23.52c-.32,0-.52-.18-.51-.44s.19-.41.53-.41C24.17,20.7,24.8,20.71,25.44,20.71Z"/>
  <path class="cls-1" d="M26.45,17.41c.32,0,.63,0,.95,0a.43.43,0,0,1,.48.41.43.43,0,0,1-.48.44H25.51a.43.43,0,1,1,0-.85C25.82,17.4,26.14,17.41,26.45,17.41Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>transformIconGreyG</title>
  <path class="cls-1" d="M14,25.37H3.29a1.67,1.67,0,0,1-1.83-1.82q0-9.39,0-18.77A1.67,1.67,0,0,1,3.27,3H13.4a.87.87,0,0,1,.67.29l3,3.17a.6.6,0,0,0,.5.22h8.26a1,1,0,0,1,.81.34c1.23,1.3,2.46,2.59,3.67,3.9a1,1,0,0,1,.21.63c0,4,0,7.94,0,11.91,0,1.25,0,2.51,0,3.76A1.68,1.68,0,0,1,28.69,29H15.83A1.67,1.67,0,0,1,14,27.18Zm3.17-6.05H14.48c-.36,0-.5-.15-.5-.5,0-.64,0-1.29,0-1.93,0-.41.13-.55.55-.55h2.62V15.7H12.81v1.58c0,.23,0,.45-.25.57s-.41,0-.58-.16c-1.33-1-2.67-2.05-4-3.09-.39-.3-.39-.57,0-.87l3.83-3c.21-.16.41-.43.75-.27s.24.49.24.76c0,.47,0,.93,0,1.4h4.34V8H13.92c-.65,0-.73-.09-.73-.72V3.83H3.39c-.82,0-1.08.26-1.08,1.07V23.54c0,.66.3,1,.92,1h13a.81.81,0,0,0,.91-.91v-4C17.17,19.53,17.16,19.44,17.15,19.32ZM25.7,7.51H18v8.8h1.15V14.66c0-.21,0-.44.26-.49a.68.68,0,0,1,.52.11c1.37,1,2.72,2.08,4.07,3.13.36.29.36.56,0,.85l-1.25,1L20,21.4c-.28.21-.56.2-.69,0a.71.71,0,0,1-.09-.38c0-.55,0-1.09,0-1.65H18v.35c0,1.34,0,2.69,0,4a1.65,1.65,0,0,1-1.63,1.64H14.83v1.79c0,.74.28,1,1,1H28.67c.73,0,1-.29,1-1V12c0-.1,0-.21,0-.33h-3.4c-.44,0-.57-.13-.57-.57V7.51Z"/>
  <path class="cls-1" d="M6.53,17.9H4.67c-.35,0-.55-.17-.55-.44s.2-.42.53-.42h3.8c.35,0,.55.16.54.44s-.2.41-.53.42Z"/>
  <path class="cls-1" d="M6.56,11.3H4.64c-.34,0-.52-.16-.52-.43s.19-.42.5-.43H8.51c.3,0,.49.19.48.44s-.19.42-.5.42Z"/>
  <path class="cls-1" d="M5.55,14.6h-1a.42.42,0,0,1-.48-.4.44.44,0,0,1,.48-.45H6.52a.44.44,0,0,1,.47.43.43.43,0,0,1-.47.42Z"/>
  <path class="cls-1" d="M25.44,15h-1.9c-.33,0-.52-.15-.53-.41s.19-.44.54-.44h3.79c.34,0,.53.15.54.42s-.19.43-.54.43Z"/>
  <path class="cls-1" d="M25.44,20.71c.64,0,1.28,0,1.92,0,.34,0,.52.16.52.43a.44.44,0,0,1-.5.42H23.52c-.32,0-.52-.18-.51-.44s.19-.41.53-.41C24.17,20.7,24.8,20.71,25.44,20.71Z"/>
  <path class="cls-1" d="M26.45,17.41c.32,0,.63,0,.95,0a.43.43,0,0,1,.48.41.43.43,0,0,1-.48.44H25.51a.43.43,0,1,1,0-.85C25.82,17.4,26.14,17.41,26.45,17.41Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-8 0 48 48">
  <defs>
    <style>.cls-1{fill:#5b5b5b;}</style>
  </defs>
  <title>transformIconOnG</title>
  <path class="cls-1" d="M14,25.37H3.29a1.67,1.67,0,0,1-1.83-1.82q0-9.39,0-18.77A1.67,1.67,0,0,1,3.27,3H13.4a.87.87,0,0,1,.67.29l3,3.17a.6.6,0,0,0,.5.22h8.26a1,1,0,0,1,.81.34c1.23,1.3,2.46,2.59,3.67,3.9a1,1,0,0,1,.21.63c0,4,0,7.94,0,11.91,0,1.25,0,2.51,0,3.76A1.68,1.68,0,0,1,28.69,29H15.83A1.67,1.67,0,0,1,14,27.18Zm3.17-6.05H14.48c-.36,0-.5-.15-.5-.5,0-.64,0-1.29,0-1.93,0-.41.13-.55.55-.55h2.62V15.7H12.81v1.58c0,.23,0,.45-.25.57s-.41,0-.58-.16c-1.33-1-2.67-2.05-4-3.09-.39-.3-.39-.57,0-.87l3.83-3c.21-.16.41-.43.75-.27s.24.49.24.76c0,.47,0,.93,0,1.4h4.34V8H13.92c-.65,0-.73-.09-.73-.72V3.83H3.39c-.82,0-1.08.26-1.08,1.07V23.54c0,.66.3,1,.92,1h13a.81.81,0,0,0,.91-.91v-4C17.17,19.53,17.16,19.44,17.15,19.32ZM25.7,7.51H18v8.8h1.15V14.66c0-.21,0-.44.26-.49a.68.68,0,0,1,.52.11c1.37,1,2.72,2.08,4.07,3.13.36.29.36.56,0,.85l-1.25,1L20,21.4c-.28.21-.56.2-.69,0a.71.71,0,0,1-.09-.38c0-.55,0-1.09,0-1.65H18v.35c0,1.34,0,2.69,0,4a1.65,1.65,0,0,1-1.63,1.64H14.83v1.79c0,.74.28,1,1,1H28.67c.73,0,1-.29,1-1V12c0-.1,0-.21,0-.33h-3.4c-.44,0-.57-.13-.57-.57V7.51Z"/>
  <path class="cls-1" d="M6.53,17.9H4.67c-.35,0-.55-.17-.55-.44s.2-.42.53-.42h3.8c.35,0,.55.16.54.44s-.2.41-.53.42Z"/>
  <path class="cls-1" d="M6.56,11.3H4.64c-.34,0-.52-.16-.52-.43s.19-.42.5-.43H8.51c.3,0,.49.19.48.44s-.19.42-.5.42Z"/>
  <path class="cls-1" d="M5.55,14.6h-1a.42.42,0,0,1-.48-.4.44.44,0,0,1,.48-.45H6.52a.44.44,0,0,1,.47.43.43.43,0,0,1-.47.42Z"/>
  <path class="cls-1" d="M25.44,15h-1.9c-.33,0-.52-.15-.53-.41s.19-.44.54-.44h3.79c.34,0,.53.15.54.42s-.19.43-.54.43Z"/>
  <path class="cls-1" d="M25.44,20.71c.64,0,1.28,0,1.92,0,.34,0,.52.16.52.43a.44.44,0,0,1-.5.42H23.52c-.32,0-.52-.18-.51-.44s.19-.41.53-.41C24.17,20.7,24.8,20.71,25.44,20.71Z"/>
  <path class="cls-1" d="M26.45,17.41c.32,0,.63,0,.95,0a.43.43,0,0,1,.48.41.43.43,0,0,1-.48.44H25.51a.43.43,0,1,1,0-.85C25.82,17.4,26.14,17.41,26.45,17.41Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>uploadDataIconOffG</title>
  <rect class="cls-1" width="40.5" height="46.5"/>
  <path class="cls-2" d="M16.66,21.21h7.18A4.15,4.15,0,0,0,28,17.06V14.3a4.15,4.15,0,0,0-4.15-4.14.83.83,0,1,0,0,1.65,2.5,2.5,0,0,1,2.49,2.49v2.76a2.49,2.49,0,0,1-2.49,2.49H16.66a2.49,2.49,0,0,1-2.49-2.49V14.3a2.5,2.5,0,0,1,2.49-2.49.83.83,0,1,0,0-1.65,4.15,4.15,0,0,0-4.15,4.14v2.76A4.15,4.15,0,0,0,16.66,21.21Z" transform="translate(0 0.07)"/>
  <path class="cls-2" d="M17.26,9l2.3-2.3v8.55a.83.83,0,1,0,1.66,0V6.74L23.51,9a.85.85,0,0,0,1.18,0,.83.83,0,0,0,0-1.17L21,4.16a.79.79,0,0,0-.58-.25.83.83,0,0,0-.59.25L16.09,7.87A.83.83,0,1,0,17.26,9Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>uploadDataIconGreyG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M16.66,21.21h7.18A4.15,4.15,0,0,0,28,17.06V14.3a4.15,4.15,0,0,0-4.15-4.14.83.83,0,1,0,0,1.65,2.5,2.5,0,0,1,2.49,2.49v2.76a2.49,2.49,0,0,1-2.49,2.49H16.66a2.49,2.49,0,0,1-2.49-2.49V14.3a2.5,2.5,0,0,1,2.49-2.49.83.83,0,1,0,0-1.65,4.15,4.15,0,0,0-4.15,4.14v2.76A4.15,4.15,0,0,0,16.66,21.21Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M17.26,9l2.3-2.3v8.55a.83.83,0,1,0,1.66,0V6.74L23.51,9a.85.85,0,0,0,1.18,0,.83.83,0,0,0,0-1.17L21,4.16a.79.79,0,0,0-.58-.25.83.83,0,0,0-.59.25L16.09,7.87A.83.83,0,1,0,17.26,9Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 38.5 44.5">
  <defs>
    <style>.cls-1{fill:none;}.cls-2{fill:#424b52;}</style>
  </defs>
  <title>uploadDataIconOnG</title>
  <rect class="cls-2" width="40.5" height="46.5"/>
  <path class="cls-1" d="M16.66,21.21h7.18A4.15,4.15,0,0,0,28,17.06V14.3a4.15,4.15,0,0,0-4.15-4.14.83.83,0,1,0,0,1.65,2.5,2.5,0,0,1,2.49,2.49v2.76a2.49,2.49,0,0,1-2.49,2.49H16.66a2.49,2.49,0,0,1-2.49-2.49V14.3a2.5,2.5,0,0,1,2.49-2.49.83.83,0,1,0,0-1.65,4.15,4.15,0,0,0-4.15,4.14v2.76A4.15,4.15,0,0,0,16.66,21.21Z" transform="translate(0 0.07)"/>
  <path class="cls-1" d="M17.26,9l2.3-2.3v8.55a.83.83,0,1,0,1.66,0V6.74L23.51,9a.85.85,0,0,0,1.18,0,.83.83,0,0,0,0-1.17L21,4.16a.79.79,0,0,0-.58-.25.83.83,0,0,0-.59.25L16.09,7.87A.83.83,0,1,0,17.26,9Z" transform="translate(0 0.07)"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <defs>
    <style>.cls-1{fill:#4c4c4c;}</style>
  </defs>
  <title>userHeaderIconOffG</title>
  <path id="Icon_metro-users" data-name="Icon metro-users" class="cls-1" d="M20.27,21.75V20.57a6.35,6.35,0,0,0,2.84-5.28c0-3.54,0-6.4-4.27-6.4s-4.26,2.86-4.26,6.4a6.33,6.33,0,0,0,2.84,5.28v1.18c-4.82.39-8.53,2.76-8.53,5.63H28.8c0-2.87-3.71-5.24-8.53-5.63Zm-9.8.55a13.51,13.51,0,0,1,4.44-1.79A9,9,0,0,1,14,19.2a8.12,8.12,0,0,1-1-3.91,12.67,12.67,0,0,1,.68-5.2A4.63,4.63,0,0,1,17.2,7.44c-.38-1.7-1.38-2.82-4-2.82-4.27,0-4.27,2.87-4.27,6.4a6.37,6.37,0,0,0,2.84,5.29v1.17c-4.82.39-8.53,2.76-8.53,5.63H9.4a8.35,8.35,0,0,1,1.07-.81Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 38 40.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>viewStoryIconOffG</title>
  <path class="cls-1" d="M27.55,17a8.73,8.73,0,0,0-3-3,8.23,8.23,0,0,0-4.27-1.13A8.42,8.42,0,0,0,13,17a1.16,1.16,0,0,0,0,1.24,8.49,8.49,0,0,0,14.59,0A1.25,1.25,0,0,0,27.55,17Zm-4.12,2.92h.07a6.46,6.46,0,0,1-8.81-2.3,6.38,6.38,0,0,1,2.12-2.17,6.52,6.52,0,0,1,2.9-1,1.29,1.29,0,0,1-.93,2.2,1.32,1.32,0,0,1-1.29-1.31,3,3,0,0,0-.39,1.5,3,3,0,0,0,.44,1.56,3.12,3.12,0,0,0,1.13,1.15,3.06,3.06,0,0,0,3.17,0A3.17,3.17,0,0,0,23,18.4a3.16,3.16,0,0,0-.29-3.57,6.59,6.59,0,0,1,1.83,1.11,6.68,6.68,0,0,1,1.33,1.57A6.58,6.58,0,0,1,23.43,19.88Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 38 40.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>viewStoryIconGreyG</title>
  <path class="cls-1" d="M27.55,17a8.73,8.73,0,0,0-3-3,8.23,8.23,0,0,0-4.27-1.13A8.42,8.42,0,0,0,13,17a1.16,1.16,0,0,0,0,1.24,8.49,8.49,0,0,0,14.59,0A1.25,1.25,0,0,0,27.55,17Zm-4.12,2.92h.07a6.46,6.46,0,0,1-8.81-2.3,6.38,6.38,0,0,1,2.12-2.17,6.52,6.52,0,0,1,2.9-1,1.29,1.29,0,0,1-.93,2.2,1.32,1.32,0,0,1-1.29-1.31,3,3,0,0,0-.39,1.5,3,3,0,0,0,.44,1.56,3.12,3.12,0,0,0,1.13,1.15,3.06,3.06,0,0,0,3.17,0A3.17,3.17,0,0,0,23,18.4a3.16,3.16,0,0,0-.29-3.57,6.59,6.59,0,0,1,1.83,1.11,6.68,6.68,0,0,1,1.33,1.57A6.58,6.58,0,0,1,23.43,19.88Z"/>
</svg>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="2 0 38 40.5">
  <defs>
    <style>.cls-2{fill:none;}.cls-1{fill:#424b53;}</style>
  </defs>
  <title>viewStoryIconOnG</title>
  <path class="cls-1" d="M27.55,17a8.73,8.73,0,0,0-3-3,8.23,8.23,0,0,0-4.27-1.13A8.42,8.42,0,0,0,13,17a1.16,1.16,0,0,0,0,1.24,8.49,8.49,0,0,0,14.59,0A1.25,1.25,0,0,0,27.55,17Zm-4.12,2.92h.07a6.46,6.46,0,0,1-8.81-2.3,6.38,6.38,0,0,1,2.12-2.17,6.52,6.52,0,0,1,2.9-1,1.29,1.29,0,0,1-.93,2.2,1.32,1.32,0,0,1-1.29-1.31,3,3,0,0,0-.39,1.5,3,3,0,0,0,.44,1.56,3.12,3.12,0,0,0,1.13,1.15,3.06,3.06,0,0,0,3.17,0A3.17,3.17,0,0,0,23,18.4a3.16,3.16,0,0,0-.29-3.57,6.59,6.59,0,0,1,1.83,1.11,6.68,6.68,0,0,1,1.33,1.57A6.58,6.58,0,0,1,23.43,19.88Z"/>
</svg>`; module.exports = data;