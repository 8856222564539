var RBEcommonDef =require('./RBEcommonDef');
var fs = require('fs');
var svgImageData = require('../images/final');

RBEsvg = {};

RBEsvg.readAndConcatenateSVGs = function()
{

    fs.readdirSync('../images/svgs').forEach(file => {
		console.log(file);
	  });
}


/**
 * 
 */
RBEsvg.initializeSVG = function(source) {
	"use strict";
	let svgDiv = $(`<div id="autoSVGs" class="hide">${svgImageData}</div>`);
    $('body').append(svgDiv);
	var autoSVGIcons = $("#autoSVGs svg");
	var svgIcons = $("#savedSVGs svg");
	//var svgIcons = [...autoSVGIcons,...savedSVGs];

	for(var index = 0; index < svgIcons.length; index++) {
		var currentSVG = svgIcons[index];
		var title = $(currentSVG).find("title").text();
		$(currentSVG).find("title").remove();
		if(title && title.length > 0) $(currentSVG).attr("id", title);
		var cssDef = $(currentSVG).find("defs");
		if ((source != "login") && cssDef && cssDef.length > 0 && title && title.length > 0) {
			var styles = cssDef.find("style").html();
			if(RBEcommonDef["nullUndef"](styles)) continue;
			if(title.indexOf("OffG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:none" || cls1 == "fill:none;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			} else if(title.indexOf("OnG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:#CACDB8" || cls1 == "fill:#CACDB8;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			} else if(title.indexOf("GreyG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:none" || cls1 == "fill:none;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			}
		}
		cssDef.remove();
		$(currentSVG).removeAttr("xmlns");
		$(currentSVG).removeAttr("xmlns:xlink");
	}
	for(var index = 0; index < autoSVGIcons.length; index++) {
		var currentSVG = autoSVGIcons[index];
		var title = $(currentSVG).find("title").text();
		$(currentSVG).find("title").remove();
		if(title && title.length > 0) $(currentSVG).attr("id", title);
		var cssDef = $(currentSVG).find("defs");
		if ((source != "login") && cssDef && cssDef.length > 0 && title && title.length > 0) {
			var styles = cssDef.find("style").html();
			if(RBEcommonDef["nullUndef"](styles)) continue;
			if(title.indexOf("OffG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:none" || cls1 == "fill:none;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			} else if(title.indexOf("OnG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:#CACDB8" || cls1 == "fill:#CACDB8;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			} else if(title.indexOf("GreyG") > 0) {
				var cls1 = styles.split("cls-1");
				cls1 = cls1[1].substr(cls1[1].indexOf("{") + 1, cls1[1].indexOf("}") - 1);
				if(cls1 && (cls1 == "fill:none" || cls1 == "fill:none;")) {
					var cls1El = $(currentSVG).find(".cls-1");
					var cls2El = $(currentSVG).find(".cls-2");
					for(var index = 0; index < cls2El.length; index++) {
						cls2El[index].classList.add("cls-1");
						cls2El[index].classList.remove("cls-2");
					}
					for(var index = 0; index < cls1El.length; index++) {
						cls1El[index].classList.add("cls-2");
						cls1El[index].classList.remove("cls-1");
					}
				}
			}
		}
		cssDef.remove();
		$(currentSVG).removeAttr("xmlns");
		$(currentSVG).removeAttr("xmlns:xlink");
	}
};

/**
 * 
 */
RBEsvg.copySVGs = function (buttonSvgIDs) {
	"use strict";
    buttonSvgIDs.forEach(function (svgID) {
    	RBEsvg.copySVG(svgID + "Button", svgID);
    });
};

/**
 * 
 */
RBEsvg.copySVG = function(btnID, svgID) {
	"use strict";
    $("div.svgOn", $("#" + btnID)).empty();
    var tabSvg = $("#" + svgID + "OnG").clone().prop('id', "on-" + btnID);
    $("div.svgOn", $("#" + btnID)).append(tabSvg);

    $("div.svgOff", $("#" + btnID)).empty();
    var tabSvg = $("#" + svgID + "OffG").clone().prop('id', "off-" + btnID);
    $("div.svgOff", $("#" + btnID)).append(tabSvg);

    $("div.svgGrey", $("#" + btnID)).empty();
    var tabSvg = $("#" + svgID + "GreyG").clone().prop('id', "grey-" + btnID);
    $("div.svgGrey", $("#" + btnID)).append(tabSvg);
};


RBEsvg.addToolTipsForStorySVGs = function (svgIDs, toolTips) {
	"use strict";
    svgIDs.forEach(function (svgID,index) {
		if(!RBEcommonDef.nullUndef(toolTips[index]))
    	RBEsvg.addToolTipForStorySVGs(svgID, toolTips[index]);
    });
};

/**
 * ONLY OFF states are appended here...
 */
RBEsvg.addToolTipForStorySVGs = function(svgID, toolTipText) {
	"use strict";
	$("#" + svgID + " .morphient-tooltiptext").remove();
    $("#" + svgID).append(`<span class="morphient-tooltiptext">${toolTipText}</span>`);
};
RBEsvg.copySVGsForStory = function (svgIDs) {
	"use strict";
    svgIDs.forEach(function (svgID) {
    	RBEsvg.copySVGForStory(svgID);
    });

};

/**
 * ONLY OFF states are appended here...
 */
RBEsvg.copySVGForStory = function(svgID) {
	"use strict";
	$("#" + svgID).empty();
    var tabSvg = $("#" + svgID + "OffG").clone().prop('id', "off-" + svgID);
    $("#" + svgID).append(tabSvg);

};

module.exports = RBEsvg;